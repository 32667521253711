import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Typography,
  Button,
  IconButton,
  Stack,
  Modal,
  Fade,
} from '@mui/material';
import {
  ApproveArrivmentButton,
  RegisterButton,
  TransportationButton,
} from '../styles/CusomPostsStyle';
import { ReactComponent as ShareIcon } from '../assets/images/share.svg';
import { ReactComponent as MagnifyingGlass } from '../assets/images/magnifying-glass.svg';
import { ReactComponent as PersonIcon } from '../assets/images/person-icon.svg';
import { ReactComponent as Placeholder } from '../assets/images/image icon.svg';

import Transportation from './Transportation';
import { handlingAfterFetch } from '../helpers/Tools';
import { sendRegisterData, userUpdateField } from '../api/api';
import AppContext from '../contexts/AppContext';
import UserContext from '../contexts/UserContext';
import AlertContext from '../contexts/AlertContext';
import { alertMessage } from '../helpers/AlertMessage';
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { width } from '@mui/system';

function CustomPostCard({ postType, post, category, marginInline }) {
  // states to handle shoing image

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState('false');
  const [showTransportation, setShowTransportation] = useState(false);
  const [showShareLinks, setShowShareLinks] = useState(false);
  const navigate = useNavigate();

  const { setLoading } = useContext(AppContext);
  const { setAlertObject } = useContext(AlertContext);

  const { loggedInUser, userFields, setUserFields } = useContext(UserContext);

  const cardStyles = {
    transition: 'all ease-out 200ms',
    borderRadius: '15px',
    maxWidth: 'max(17.760vw, 327px)',
    width: '100%',
    marginInline: marginInline ? marginInline : 0,
    display: 'flex',
    flexDirection: 'column',
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = (value) => {
    setImage(value);
    setOpen(true);
  };

  const confirmArrival = async () => {
    setLoading(true);

    await handlingAfterFetch(
      sendRegisterData(post.id, {}, loggedInUser.id),
      (res) => {
        if (res.status === 200) {
          alertMessage('תודה, קיבלנו את ההרשמה שלך', 'success');
          addToMyEvents();
          setLoading(false);
        } else {
          alertMessage('שגיאה פנימית', 'error');
          setLoading(false);
        }
      },
      setAlertObject,
    );
  };

  const addToMyEvents = async (id) => {
    if (postType != 'event') return;
    let my_posts = userFields.events;
    // Check if the number exists in the array
    if (!my_posts.includes(post.id || post.ID)) {
      // If the number doesn't exist, add it to the array
      my_posts.push(post.id || post.ID);
    }

    let data = {
      id: loggedInUser.id,
      field_name: 'events',
      field_value: my_posts,
    };
    await handlingAfterFetch(
      userUpdateField(data),
      (res) => {
        if (res.status === 200 && res.fields) {
          setUserFields(res.fields);

          setLoading(false);
        } else {
          setLoading(false);
          alert('Some problem with Adding to my galleries');
        }
      },
      setAlertObject,
    );
  };

  const getNavigateURL = () => {
    // if (postType === 'activity')
    //   return `/activities/${post.slug}/${post.id}`;

    return `/${postType}s/${post.slug}/${post.id}`;
  };

  return (
    <>
      <Card sx={cardStyles}>
        <Box
          className="image-wrapper"
          sx={{ aspectRatio: 1.7 }}
          onClick={(e) => {
            e.target.tagName.toLowerCase() === 'img' &&
              post?.acf[`${postType}_content_text`] &&
              navigate(getNavigateURL());
          }}
        >
          <>
            <IconButton
              className="tag"
              sx={{
                columnGap: '8px',
              }}
            >
              {category &&
                (category.acf.icon_svg ? (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: category?.acf.icon_svg,
                    }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '18px',
                      height: '18px',
                      svg: {
                        path: {
                          stroke: 'white!important',
                          fill: 'transparent!important',
                        },
                        line: {
                          stroke: 'white!important',
                          fill: 'transparent!important',
                        },
                        circle: {
                          stroke: 'white!important',
                          fill: 'transparent!important',
                        },
                      },
                    }}
                  />
                ) : (
                  ''
                ))}
              <span>{category?.name}</span>
            </IconButton>
            <IconButton
              className="magnify"
              onClick={(e) => {
                handleImage(post?.acf?.[`${postType}_image`]?.url);
              }}
            >
              <MagnifyingGlass />
            </IconButton>
            {post?.acf[`${postType}_image`]?.url ? (
              <img
                src={
                  post?.acf[`${postType}_image`]?.url ||
                  'https://theguy4u.co.il/tempo/wp-content/uploads/woocommerce-placeholder.png'
                }
                alt="Post"
                style={{
                  height: 'max(11.81vw, 211px)',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Box sx={{ path: { stroke: 'gray' } }}>
                <Placeholder
                  style={{
                    width: '100%',
                    height: 'max(11.81vw, 211px)',
                  }}
                />
              </Box>
            )}
          </>
        </Box>
        <Stack
          className="post-details"
          direction="column"
          spacing={1}
          onClick={(e) => {
            e.target.tagName.toLowerCase() !== 'svg' &&
              e.target.tagName.toLowerCase() !== 'button' &&
              e.target.tagName.toLowerCase() !== 'path' &&
              e.target.tagName.toLowerCase() !== 'circle' &&
              post?.acf[`${postType}_content_text`] &&
              navigate(getNavigateURL());
          }}
        >
          <Stack
            direction="row"
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Typography
              component="h2"
              variant="h2"
              sx={{ flexGrow: 1 }}
              dangerouslySetInnerHTML={{
                __html: post?.title?.rendered || post?.post_title,
              }}
            ></Typography>
            {showShareLinks && (
              <Stack direction="row" spacing={1} className="share-links">
                <FacebookShareButton
                  url={`${window.location.href}/${post?.slug}/${post?.ID}`}
                  style={{ height: '24px', width: '24px' }}
                >
                  <FacebookIcon size={24} round={true}></FacebookIcon>
                </FacebookShareButton>
                <WhatsappShareButton
                  url={`${window.location.href}/${post?.slug}/${post?.ID}`}
                  style={{ height: '24px', width: '24px' }}
                >
                  <WhatsappIcon size={24} round={true} />
                </WhatsappShareButton>
              </Stack>
            )}
            <IconButton
              className="share"
              sx={{ marginLeft: '8px' }}
              onClick={() => setShowShareLinks(!showShareLinks)}
            >
              <ShareIcon />
            </IconButton>
          </Stack>
          <Typography>{post?.acf[`${postType}_date`]}</Typography>
          {post?.acf.event_price && (
            <Typography>{`מחיר: ${post?.acf.event_price} ש"ח`}</Typography>
          )}
          {post?.acf.is_limited_register && (
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <PersonIcon />
              <Typography>
                {`${
                  post?.acf?.is_register_needed &&
                  +post?.acf?.maximum_registers - +post?.acf?.registered_users
                } מקומות פנויים`}
              </Typography>
            </Stack>
          )}
        </Stack>

        <Stack
          direction={'row'}
          justifyContent={postType === 'event' ? 'space-between' : 'flex-end'}
          spacing={2}
          sx={{
            margin: '0 20px 25px',
          }}
        >
          {postType === 'event' ? (
            <>
              {post?.acf?.is_transportation_needed && (
                <Button
                  sx={TransportationButton}
                  onClick={() => {
                    setShowTransportation(true);
                    // document.body.style.overflow = 'hidden';
                  }}
                >
                  יש גם הסעות
                </Button>
              )}
              {post?.acf?.is_register_needed ? (
                <Link
                  to={
                    +post?.acf?.maximum_registers >
                      +post?.acf?.registered_users ||
                    (userFields?.events &&
                      userFields?.events?.includes(+post.id || +post.ID))
                      ? `/registration/${encodeURIComponent(
                          post?.slug,
                        )}/${post?.id}`
                      : '##'
                  }
                >
                  <Button
                    variant="containedRed"
                    sx={RegisterButton}
                    disabled={
                      +post?.acf?.maximum_registers <=
                      +post?.acf?.registered_users
                    }
                  >
                    {+post?.acf?.maximum_registers <=
                    +post?.acf?.registered_users
                      ? 'אין מקומות פנויים'
                      : userFields?.events &&
                          userFields?.events?.includes(+post.id || +post.ID)
                        ? 'עדכון הרשמה'
                        : 'להרשמה'}
                  </Button>
                </Link>
              ) : (
                <Button
                  variant="outlineBlue"
                  sx={ApproveArrivmentButton}
                  onClick={() => {
                    confirmArrival();
                  }}
                  disabled={
                    userFields?.events &&
                    userFields?.events?.includes(+post.id || +post.ID)
                  }
                >
                  {userFields?.events &&
                  userFields?.events?.includes(+post.id || +post.ID)
                    ? 'ההגעה אושרה'
                    : 'לאישור הגעה'}
                </Button>
              )}
            </>
          ) : (
            <Link
              // to={
              // 	post?.acf?.purchase_link
              // 		? post?.acf?.purchase_link
              // 		: getNavigateURL()
              // }
              to={`/benefits/${post?.slug}/${post?.id}`}
              // target={post?.acf?.purchase_link && '_blank'}
            >
              {
                <Button variant="containedRed" sx={RegisterButton}>
                  לצפייה
                </Button>
              }
            </Link>
          )}
        </Stack>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundcolor: 'red',
            },
          }}
        >
          <Fade
            in={open}
            timeout={500}
            style={{
              outline: 'none',
              height: '26vh',
              objectFit: 'contain',
            }}
          >
            <img
              src={image}
              alt="Post"
              style={{
                maxHeight: '90%',
                maxWidth: '90%',
              }}
            />
          </Fade>
        </Modal>
      </Card>
      {showTransportation && (
        <Transportation
          title={post?.post_title}
          transportations={post?.acf?.transportations}
          showTransportation={showTransportation}
          setShowTransportation={setShowTransportation}
        />
      )}
    </>
  );
}

export default CustomPostCard;
