import React, { useState, useEffect, startTransition, useContext } from 'react';

import { fetchPostsCategories } from '../api/api.js';
import UserContext from './UserContext.js';
import { handlingAfterFetch } from '../helpers/Tools.js';

const TheAppContext = React.createContext({});

export default TheAppContext;

export const AppContextProvider = ({ children }) => {
  const [postsCategories, setPostsCategories] = useState([]);
  const { loggedInUser } = useContext(UserContext);

  const [optionsFields, setOptionsFields] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchCategories = async () => {
    await handlingAfterFetch(fetchPostsCategories('post'), (res) => {
      res.status === 200 &&
        startTransition(() => {
          setPostsCategories(res.fields);
        });
    });
  };

  useEffect(() => {
    if (loggedInUser) fetchCategories();
  }, [loggedInUser]);

  const appContextData = {
    optionsFields,
    setOptionsFields,
    loading,
    setLoading,
    postsCategories,
    setPostsCategories,
  };

  return (
    <TheAppContext.Provider value={appContextData}>
      {children}
    </TheAppContext.Provider>
  );
};
