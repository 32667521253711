import React, { useState } from 'react';
import { Box, Stack } from '@mui/system';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import { ReactComponent as BusIcon } from '../assets/images/bus-icon.svg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  transportationCard: {
    backgroundColor: theme.palette.primary.extra_light,
    color: theme.palette.primary.dark,
    borderRadius: '25px',
    padding: '12px 16px 35px',
    maxHeight: '20px',
    overflow: 'hidden',
    fontSize: '18px',
    lineHeight: 1,
    transition: 'all 0.4s ease',
    flexGrow: 1,
  },

  openCard: {
    maxHeight: '100vh',
  },
}));

function TransportationCard({
  transportationDetails: { bus_location, depart_time, stations },
}) {
  const [openCard, setOpenCard] = useState(false);

  const classes = useStyles();

  const handleOpenCard = () => {
    setOpenCard(!openCard);
  };

  return (
    <Box
      className={
        openCard
          ? `${classes.transportationCard} ${classes.openCard}`
          : classes.transportationCard
      }
      onClick={() => handleOpenCard()}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ marginBottom: '12px', cursor: 'pointer' }}
      >
        <BusIcon />
        <Box>
          <Typography>{bus_location}</Typography>
        </Box>
      </Stack>
      <Typography>שעת יציאה: {depart_time}</Typography>
      <Typography sx={{ fontWeight: 700 }}>תחנות עצירה:</Typography>
      <Stack direction="row">
        <List>
          {stations.map((station, index) => {
            return (
              <ListItem key={index} sx={{ paddingBlock: 'unset' }}>
                <ListItemText>{station.station}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Stack>
    </Box>
  );
}

export default TransportationCard;
