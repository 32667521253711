import { useContext, useEffect } from 'react';
import UserContext from '../contexts/UserContext';
import AlertContext from '../contexts/AlertContext';

import { Navigate, useLocation, useNavigate } from 'react-router';
import jwtDecode from 'jwt-decode'; // You need to install this library
import { fetchVerifiedUser } from '../api/api';

import Home from '../screens/Home';
import { handlingAfterFetch } from './Tools';
import { fetchCart } from '../api/shopApi';
// import CartContext from '../contexts/CartContext';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const { setAlertObject } = useContext(AlertContext);
  const { loggedInUser, setLoggedInUser, setUserFields } =
    useContext(UserContext);

  const verifyUserSessionIsActive = async () => {
    // Check if there is a token stored in local storage
    let token = localStorage.getItem('token');

    if (!token) {
      navigate('/login', {
        state: {
          pathname: location.pathname,
          search: location.search,
        },
      });
      //   return <Navigate to='/login' replace={true} />;
    } else {
      const decodedToken = jwtDecode(token);

      try {
        const verifiedUser = await fetchVerifiedUser(
          token,
          decodedToken.data.user.id,
        );

        if (verifiedUser.success) {
          setUserFields(verifiedUser.fields);
          setLoggedInUser({
            ...decodedToken.data.user,
          });

          //   handleFetchCartData();

          navigate(location.pathname + location?.search, {
            state: { search: location.search },
          });
        } else {
          navigate('/login', {
            state: {
              pathname: location.pathname,
              search: location.search,
            },
          });
        }
      } catch (error) {
        console.log('errorrr:', error);
        navigate('/login', {
          state: {
            pathname: location.pathname,
          },
        });
      }
    }
  };

  useEffect(() => {
    verifyUserSessionIsActive();
  }, []);
  if (loggedInUser === undefined) {
    return <Home></Home>;
  }

  if (!loggedInUser) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
