import { IconButton, Typography } from '@mui/material';
import { Box, Stack, Container } from '@mui/system';
import React from 'react';
import TransportationCard from './TransportationCard';
import { ReactComponent as BackArrow } from '../assets/images/back-arrow.svg';
import TempoLogo from '../assets/images/LOGO2.png';

function Transportation({
  title,
  transportations,
  showTransportation,
  setShowTransportation,
}) {
  return (
    <Container
      className="blank"
      sx={{
        inset: '0 !important',
        transform: 'unset !important',
        width: '100%',
        maxWidth: 'unset !important',
        backgroundColor: 'rgba(0, 0, 0, 0.7) !important',
        zIndex: '1000 !important',
        width: '100% !important',
      }}
      onClick={(e) => {
        e.target === e.currentTarget && setShowTransportation(false);
      }}
      // sx={
      // 	showTransportation && {
      // 		// overflow: 'auto',
      // 		width: '400px',
      // 		height: '70% !important',
      // 		top: '50% !important',
      // 		left: '50% !important',
      // 		transform: 'translate(-50%, -50%) !important',
      // 		borderRadius: '15px !important',
      // 		boxShadow:
      // 			'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important',
      // 	}
      // }
    >
      <Box
        sx={{
          backgroundColor: 'white',
          height: '70% !important',
          width: '400px',
          position: 'absolute',
          top: '50% !important',
          left: '50% !important',
          transform: 'translate(-50%, -50%) !important',
          borderRadius: '15px !important',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important',
          paddingInline: '15px',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="header"
        >
          <img src={TempoLogo} alt="Logo" />
          <IconButton
            onClick={() => {
              setShowTransportation(false);
              document.body.style.overflow = 'auto';
            }}
          >
            <BackArrow />
          </IconButton>
        </Stack>
        <Stack
          className="page-titles"
          direction="column"
          spacing={1}
          sx={{ marginBottom: '7px' }}
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 400,
              color: '#0F2A8D',
              lineHeight: '1.1',
            }}
          >
            הסעות
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 400,
              color: '#0F2A8D',
              lineHeight: '1.5',
              marginBottom: '16px !important',
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={3}>
          {transportations.map((transportation, index) => {
            return (
              <TransportationCard
                key={index}
                transportationDetails={transportation}
              />
            );
          })}
        </Stack>
      </Box>
    </Container>
  );
}

export default Transportation;
