import React, { useState } from 'react';

const UserContext = React.createContext({});
export default UserContext;

export const UserContextProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState(undefined);
  const [userFields, setUserFields] = useState(undefined);

  // useEffect(() => {
  //   if (loggedInUser) fetchCategories();
  // }, [loggedInUser]);

  const UserContextData = {
    loggedInUser,
    setLoggedInUser,
    userFields,
    setUserFields,
  };

  return (
    <UserContext.Provider value={UserContextData}>
      {children}
    </UserContext.Provider>
  );
};
