import React, { useState } from 'react';

// Mui
import theme from '../../styles/CustomTheme';
import { Box, Stack, Button } from '@mui/material/';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { makeStyles } from '@mui/styles';

// import required modules
import { Navigation, Thumbs } from 'swiper/modules'; //if you want to use navigation
import { Swiper, SwiperSlide } from 'swiper/react';

const useStyles = makeStyles((theme) => ({
  mySwiper: {
    '.swiper-wrapper': {
      alignItems: 'center',
    },
  },
}));

function Yad2ImagesPopup({ popupImages, setPopup }) {
  const [swiper, setSwiper] = useState(null);
  const classes = useStyles();
  return (
    <Box
      sx={{
        width: '100%',
        maxHeight: '100vh',
        position: 'fixed',
        zIndex: 999,
        top: '50%',
        left: '0',
        transform: 'translateY(-50%)',
      }}
    >
      <Box
        onClick={() => setPopup(false)}
        sx={{
          inset: 0,
          position: 'absolute',
          zIndex: 3,
          left: '0',
          //   height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          cursor: 'pointer',
        }}
      ></Box>
      <Swiper
        style={{
          width: '100%',
          overflow: 'visible',
          zIndex: 3,
          //   top: '50%',
          height: '100vh',
          //   left: '0',
          //   transform: 'translateY(-50%)',
          // marginInline: 'auto',
          '--swiper-navigation-color': `${theme.palette.secondary.main}`,
        }}
        spaceBetween={10}
        centeredSlides={true}
        slidesPerView={1}
        autoHeight={true}
        navigation
        loop={true}
        onSwiper={(swiper) => {
          setSwiper(swiper);
        }}
        modules={[Navigation]}
        className="Yad2PopupSlider"
        dir="rtl"
      >
        {popupImages.map((image, idx) => {
          return (
            <SwiperSlide key={idx} style={{ width: '100%', display: 'flex' }}>
              <img
                src={image.url}
                alt="gallery"
                style={{ width: '100%', objectFit: 'contain' }}
              />
            </SwiperSlide>
          );
        })}
        <CloseOutlinedIcon
          onClick={() => setPopup(false)}
          color="my_white"
          sx={{
            position: 'absolute',
            top: '25px',
            left: '25px',
            zIndex: '999',
            cursor: 'pointer',
            backgroundColor: theme.palette.background.overlay,
            borderRadius: '100vw',
            padding: '10px',
            width: '50px',
            height: '50px',
          }}
        />
      </Swiper>
    </Box>
  );
}

export default Yad2ImagesPopup;
