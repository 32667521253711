import { Box } from '@mui/material';
import React from 'react';

function SurveySliderItem({ color }) {
  const blueStyle = {
    borderRadius: '15px',
    background: '#2DC8FA',
    boxShadow: '0px 0px 40px 0px rgba(15, 42, 141, 0.35)',
    width: '42px',
    height: '8px',
  };

  const whiteStyle = {
    borderRadius: '15px',
    background: '#fff',
    boxShadow: '0px 0px 40px 0px rgba(15, 42, 141, 0.35)',
    width: '42px',
    height: '8px',
  };

  return <Box sx={color === 'white' ? whiteStyle : blueStyle}></Box>;
}

export default SurveySliderItem;
