import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Stack,
  TextField,
  Button,
  Table,
  TableContainer,
  TableBody,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
} from '@mui/material/';
import { styled } from '@mui/material/styles';
import { handlingAfterFetch } from '../helpers/Tools';
import { fetchAllRegisteredEvents } from '../api/api';
import { alertMessage } from '../helpers/AlertMessage';
import AppContext from '../contexts/AppContext';
import AlertContext from '../contexts/AlertContext';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import moment from 'moment';

function SummerCampReport() {
  const [startDate, setStartDate] = useState(
    moment().startOf('week').day(0).format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    moment().startOf('week').day(6).format('YYYY-MM-DD'),
  );
  const [startDateHelper, setStartDateHelper] = useState(startDate);
  const [endDateHelper, setEndDateHelper] = useState(endDate);
  const [usersOrder, setUsersOrder] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const { setAlertObject } = useContext(AlertContext);
  const { setLoading } = useContext(AppContext);
  const [currentTable, setCurrentTable] = useState([]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleGetAllPosts = async () => {
    await handlingAfterFetch(
      fetchAllRegisteredEvents(true),
      // fetchAllPosts('event'),
      (res) => {
        console.log('res', res);
        if (res.status === 200) {
          setUsersOrder(res.fields);
        } else {
          alertMessage('שגיאה פנימית', 'error');
        }
        setLoading(false);
      },
      setAlertObject,
    );
  };

  const handleGetAllDepartments = () => {
    const temp = [];

    usersOrder.forEach((order) => {
      if (!temp.includes(order.user_department))
        temp.push(order.user_department);
    });

    setDepartments(temp);
  };

  const handleSetDates = () => {
    setStartDate(startDateHelper);
    setEndDate(endDateHelper);
  };

  const resetDates = () => {
    setStartDate(`${moment().format('YYYY')}-${moment().format('MM')}-01`);
    setEndDate(moment().format('YYYY-MM-DD'));
  };

  // check if date is before the end date
  const isBefore = (date, endDate) => {
    let theDate = new Date(endDate);
    theDate.setDate(theDate.getDate());

    return moment(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')).isBefore(
      moment(theDate),
    );
  };
  // check if date is after the start date
  const isAfter = (date, startDate) => {
    let theDate = new Date(startDate);
    theDate.setDate(theDate.getDate() - 1);

    return moment(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')).isAfter(
      moment(theDate),
    );
  };

  const handleGetTablesData = () => {
    const table = document.querySelector('.MuiTable-root');
    const tableData = [];

    const headers = Array.from(
      table.querySelectorAll('.MuiTableCell-head'),
    ).map((th) => th.textContent.trim());
    const rows = table.querySelectorAll('.MuiTableRow-root');

    for (let j = 1; j < rows.length; j++) {
      // Start from 1 to skip the header row
      const cells = rows[j].querySelectorAll('.MuiTableCell-body');
      const rowData = {};

      headers.forEach((header, index) => {
        if (cells[index].querySelector('table')) {
          rowData[header] = '';
        } else {
          rowData[header] = cells[index]
            ? cells[index].textContent.trim()
            : null;
        }
      });

      tableData.push(rowData);
    }

    const childrenTables = document.querySelectorAll('.children-table');
    const childrenTablesData = [];

    for (let i = 0; i < childrenTables.length; i++) {
      childrenTablesData.push([]);

      const headers = Array.from(childrenTables[i].querySelectorAll('th')).map(
        (th) => th.textContent.trim(),
      );
      const rows = childrenTables[i].querySelectorAll('tr');

      for (let j = 1; j < rows.length; j++) {
        // Start from 1 to skip the header row
        const cells = rows[j].querySelectorAll('td');
        const rowData = {};

        headers.forEach((header, index) => {
          if (cells[index].querySelector('table')) {
            rowData[header] = '';
          } else {
            rowData[header] = cells[index]
              ? cells[index].textContent.trim()
              : null;
          }
        });

        childrenTablesData[i].push(rowData);
      }
    }

    const finalHeaders = [];
    const tempData = [];
    const finalData = [];

    if (childrenTablesData.length) {
      Object.keys(tableData[0]).forEach((tableHeader) => {
        if (tableHeader === 'פרטי ילדים') {
          Object.keys(childrenTablesData[0][0]).forEach(
            (childrenTableHeader) => {
              finalHeaders.push(childrenTableHeader);
            },
          );
        } else {
          finalHeaders.push(tableHeader);
        }
      });
    }

    if (finalHeaders.length) {
      tableData.forEach((data, index) => {
        childrenTablesData[index].forEach((children, childrenIndex) => {
          const obj = {};
          finalHeaders.forEach((header) => {
            if (data[header]) {
              obj[header] = childrenIndex === 0 ? data[header] : '';
            } else if (children[header]) {
              obj[header] = children[header];
            } else {
              obj[header] = '';
            }
          });
          tempData.push(obj);
        });
      });
    }

    // finalData.push(tempData);
    // finalData.push(tableData);
    // setCurrentTable(finalData);

    setCurrentTable(tempData);
  };

  useEffect(() => {
    handleGetAllPosts('event');
    setLoading(true);
  }, []);

  useEffect(() => {
    handleGetAllDepartments();
  }, [usersOrder]);

  useEffect(() => {
    handleGetTablesData();
  }, [startDate, endDate, selectedDepartment]);

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(currentTable);
    // const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    XLSX.utils.book_append_sheet(workbook, worksheet, `Table`);

    const wbout = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'binary',
    });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };

    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      'tables.xlsx',
    );
  };

  const renderAttractions = () => {
    if (selectedDepartment) {
      return usersOrder.map((data, index) => {
        if (
          selectedDepartment === data?.user_department &&
          isBefore(data?.event_date, endDate) &&
          isAfter(data?.event_date, startDate) &&
          data.is_summer_camp
        ) {
          return (
            <StyledTableRow key={index}>
              <StyledTableCell align="left">{index + 1}</StyledTableCell>
              <StyledTableCell align="left">{data?.order_id}</StyledTableCell>
              <StyledTableCell align="left">{data?.event_date}</StyledTableCell>
              <StyledTableCell align="left">{data?.user_id}</StyledTableCell>
              <StyledTableCell align="left">{data?.user_name}</StyledTableCell>
              <StyledTableCell align="left">
                {data?.user_department}
              </StyledTableCell>
              <StyledTableCell align="left">
                {data?.user_branch}
              </StyledTableCell>
              <StyledTableCell align="left">{data?.user_tel}</StyledTableCell>
              <StyledTableCell align="left">{data?.event_name}</StyledTableCell>
              <StyledTableCell align="left">
                {renderChildDetails(
                  data?.registered.filter(
                    (register) => +register.user === +data.user_id,
                  ),
                )}
              </StyledTableCell>
              {/* <StyledTableCell align='left'>
								{+data?.user_qty ? +data?.user_qty : 0}
							</StyledTableCell> */}
            </StyledTableRow>
          );
        }
      });
    } else {
      return usersOrder.map((data, index) => {
        if (
          isBefore(data?.event_date, endDate) &&
          isAfter(data?.event_date, startDate) &&
          data.is_summer_camp
        ) {
          return (
            <StyledTableRow key={index}>
              <StyledTableCell align="left">{index + 1}</StyledTableCell>
              <StyledTableCell align="left">{data?.order_id}</StyledTableCell>
              <StyledTableCell align="left">{data?.event_date}</StyledTableCell>
              <StyledTableCell align="left">{data?.user_id}</StyledTableCell>
              <StyledTableCell align="left">{data?.user_name}</StyledTableCell>
              <StyledTableCell align="left">
                {data?.user_department}
              </StyledTableCell>
              <StyledTableCell align="left">
                {data?.user_branch}
              </StyledTableCell>
              <StyledTableCell align="left">{data?.user_tel}</StyledTableCell>
              <StyledTableCell align="left">{data?.event_name}</StyledTableCell>
              <StyledTableCell align="left">
                {renderChildDetails(
                  data?.registered.filter(
                    (register) => +register.user === +data.user_id,
                  ),
                )}
              </StyledTableCell>
              {/* <StyledTableCell align='left'>
								{+data?.user_qty ? +data?.user_qty : 0}
							</StyledTableCell> */}
            </StyledTableRow>
          );
        }
      });
    }
  };

  const renderChildDetails = (details) => {
    let slicedArray = [];
    for (let i = 0; i < details[0].register_questions.length; i += 5) {
      let chunk = details[0].register_questions.slice(i, i + 5);
      slicedArray.push(chunk);
    }

    return (
      <table
        className="children-table"
        style={{
          border: '1px solid black',
        }}
      >
        <thead>
          <tr>
            {/* <th style={{ paddingInline: '7px' }} align='center'>
							#
						</th> */}
            {slicedArray[0].map((detail, index) => {
              return (
                <th key={index} style={{ paddingInline: '7px' }} align="center">
                  {detail.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {slicedArray.map((detail, index) => {
            return (
              <tr key={index}>
                {/* <td align='center'>{index + 1}</td> */}
                {detail.map((data, key) => {
                  return (
                    <td
                      key={key}
                      style={{ paddingInline: '7px' }}
                      align="center"
                    >
                      {data.answers[0] ? data.answers[0].answer : '-'}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Container>
      <Stack
        className="title-dates"
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{
          marginTop: '20px',
          borderBottom: '1px black solid',
        }}
      >
        <Typography
          component="h1"
          variant="h1"
          style={{
            fontSize: '36px',
            fontWeight: 500,
            lineHeight: 1.1,
          }}
        >
          דוח קייטנות
        </Typography>
        <Typography>{`${endDate} - ${startDate}`}</Typography>
      </Stack>

      <Stack
        direction="column"
        justifyContent="space-between"
        className="dates-buttons-wrapper"
        sx={{ marginBottom: '40px' }}
      >
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={exportToExcel}>טבלת EXCEL</Button>
          <Button onClick={() => window.print()}>הדפסה</Button>
        </Stack>
        <Stack direction="row" className="dates-picker" spacing={3}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography>תאריך התחלה</Typography>
            <TextField
              type="date"
              onChange={(e) => setStartDateHelper(e.target.value)}
              value={startDateHelper}
              sx={{
                border: '1px solid black',
                borderRadius: '5px',

                '& input': {
                  padding: '5px 10px',
                },
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography>תאריך סיום</Typography>
            <TextField
              type="date"
              onChange={(e) => setEndDateHelper(e.target.value)}
              value={endDateHelper}
              sx={{
                border: '1px solid black',
                borderRadius: '5px',

                '& input': {
                  padding: '5px 10px',
                },
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography>מחלקה</Typography>
            <select
              name="department"
              id="department"
              onChange={(e) =>
                setSelectedDepartment(e.target.value ? e.target.value : null)
              }
              style={{
                outline: 'none',
                fontSize: '16px',
                padding: '8px 20px',
                borderRadius: '4px',
                borderColor: '#000',
              }}
            >
              <option value="">בחר מחלקה</option>
              {departments.map((department, key) => {
                return (
                  <option key={key} value={department}>
                    {department}
                  </option>
                );
              })}
            </select>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Button
              onClick={() => {
                handleSetDates();
              }}
            >
              סינון
            </Button>

            <Button
              onClick={() => {
                resetDates();
              }}
            >
              נקה
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Box className="table">
        <TableContainer component={Paper} sx={{ marginBottom: '50px' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell># הזמנה</StyledTableCell>
                <StyledTableCell>תאריך</StyledTableCell>
                <StyledTableCell># עובד</StyledTableCell>
                <StyledTableCell>שם</StyledTableCell>
                <StyledTableCell>מחלקה</StyledTableCell>
                <StyledTableCell>ענף</StyledTableCell>
                <StyledTableCell># טלפון</StyledTableCell>
                <StyledTableCell>שם אירוע</StyledTableCell>
                <StyledTableCell>פרטי ילדים</StyledTableCell>
                {/* <StyledTableCell>כמות משתתפים</StyledTableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>{renderAttractions()}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}

export default SummerCampReport;
