import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertContext from '../../contexts/AlertContext';

import LogoLoader from '../LogoLoader.jsx';
import { useWindowSize } from '../../helpers/useWindowSize.jsx';

import moment from 'moment';

import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { EffectCards } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

import { ReactComponent as ImageSvg } from '../../assets/images/image icon.svg';

// Mui
import theme from '../../styles/CustomTheme.jsx';
import { Box, Button, Stack, Typography } from '@mui/material';

// functions
import { fetchPostById } from '../../api/api.js';
import { handlingAfterFetch } from '../../helpers/Tools.js';

function GreenPageGalleries({ galleryId }) {
  const navigate = useNavigate();
  const [width, height] = useWindowSize();
  const { setAlertObject } = useContext(AlertContext);

  const [gallery, setGallery] = useState(undefined);

  const getGallery = async () => {
    await handlingAfterFetch(
      fetchPostById(galleryId, 'gallery'),
      (res) => {
        if (res.status === 200 && res) {
          setGallery(res.fields);
        } else {
          alert('Some problem with Getting Page info');
        }
      },
      setAlertObject,
    );
  };

  useEffect(() => {
    if (galleryId) {
      getGallery();
    }
  }, [galleryId]);

  return (
    <>
      {gallery ? (
        <Box sx={{ paddingTop: '53px' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ marginBottom: '20px' }}
          >
            <Typography
              color={theme.palette.primary.main}
              sx={{
                typography: {
                  xs: 'h2',
                  sm: 'headline1',
                },
              }}
            >
              גלריית תמונות
            </Typography>
            <Button variant="containedLightBlue">לפנאי </Button>
          </Stack>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            justifyContent="space-between"
          >
            {gallery && (
              <Stack sx={{ maxWidth: { sm: '40%' } }}>
                <Stack
                  direction={{
                    xs: 'row',
                    sm: 'column',
                  }}
                  alignItems={{
                    xs: 'start',
                    sm: 'start',
                  }}
                >
                  <ImageSvg
                    style={{
                      stroke: theme.palette.primary.dark,
                      marginTop: '3px',
                    }}
                  />

                  <Typography
                    variant="h3"
                    color="primary.main"
                    sx={{ marginBottom: '25px' }}
                  >
                    {gallery?.title?.rendered}
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Swiper
                    effect={'cards'}
                    cardsEffect={{
                      perSlideOffset: 25,
                      perSlideRotate: 0,
                    }}
                    style={{
                      width: '80%',
                      overflow: 'visible',
                      marginInlineStart: '0',
                      padding: '25px 25px 0 25px',
                    }}
                    slidesPerView={1}
                    loop={true}
                    modules={[EffectCards]}
                    spaceBetween={10}
                    dir="rtl"
                    className="mySwiper"
                  >
                    {gallery?.acf?.images?.map((image, idx) => {
                      return (
                        <SwiperSlide
                          key={idx}
                          data-index={idx}
                          style={{
                            borderRadius: '15px',
                            overflow: 'hidden',
                            boxShadow:
                              '0px 0px 20px 0px rgba(13, 47, 92, 0.20)',
                            height: width < 768 ? '350px' : '480px',
                          }}
                        >
                          <img
                            src={image.image.url}
                            alt=""
                            style={{
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Box>
                <Stack direction="row" justifyContent="space-between">
                  <Button
                    variant="containedRed"
                    sx={{
                      width: 'fit-content',
                      marginBlock: '20px',
                      paddingInline: '10px',
                    }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(`/galleries?galleryId=${gallery.id}`);
                    }}
                  >
                    צפה באלבום
                  </Button>
                  <Button
                    variant="outlineBlue"
                    sx={{
                      width: 'fit-content',
                      marginBlock: '20px',
                    }}
                    onClick={() => navigate('/galleries')}
                  >
                    לכל אלבומי אירועי טמפו
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
      ) : (
        <LogoLoader
          color="primary"
          style={{
            marginInline: 'auto',
            display: 'block',
          }}
        />
      )}
    </>
  );
}

export default GreenPageGalleries;
