import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Slide,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import theme from '../styles/CustomTheme';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import React, { useContext } from 'react';
import AlertContext from '../contexts/AlertContext';
import Draggable from 'react-draggable';

export const DEFAULT_DIALOG_BUTTONS = {
  ok: 'OK',
  cancel: 'CANCEL',
};
export const DEFAULT_DIALOG_PARAMETERS = {
  title: 'Title',
  description: 'Description!',
  content: null,
};
export const DEFAULT_DIALOG_HANDLERS = {
  call_on_submit: null,
  call_on_cancel: null,
};
export const HIDDEN_DIALOG_OBJECT = {
  open: false,
  ...DEFAULT_DIALOG_HANDLERS,
  ...DEFAULT_DIALOG_BUTTONS,
  ...DEFAULT_DIALOG_PARAMETERS,
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[className*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
      />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogPopupContainer = () => {
  const { dialogObject, setDialogObject } = useContext(AlertContext);

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    if (reason === 'clickaway') {
      return;
    }
    const { call_on_cancel } = dialogObject;
    setDialogObject({
      ...dialogObject,
      open: false,
    });
    if (call_on_cancel) {
      call_on_cancel();
    }
  };

  const handleSubmit = (event, reason) => {
    event.preventDefault();
    if (reason === 'clickaway') {
      return;
    }
    const { call_on_submit } = dialogObject;
    setDialogObject({
      ...dialogObject,
      open: false,
    });
    if (call_on_submit) {
      call_on_submit();
    }
  };

  return (
    <div>
      <Dialog
        open={dialogObject?.open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        disableEscapeKeyDown
      >
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '45px',
            padding: '30px',
            textAlign: 'center',
          }}
        >
          <ClearOutlinedIcon
            onClick={handleClose}
            sx={{
              fill: 'white',
              position: 'absolute',
              right: '0',
              top: '0',
              backgroundColor: theme.palette.button.main,
              borderRadius: '100vw',
              width: '34px',
              height: '34px',
              path: {
                // stroke: 'white',
              },
            }}
          ></ClearOutlinedIcon>
          {dialogObject.title && (
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              <Typography variant="subtitle">{dialogObject.title}</Typography>
            </DialogTitle>
          )}
          {(dialogObject.content || dialogObject.description) && (
            <DialogContent>
              <DialogContentText>
                <Typography variant="runningText2">
                  {dialogObject.description}
                </Typography>
              </DialogContentText>
              {/* <hr /> */}
              {dialogObject.content}
            </DialogContent>
          )}
          {dialogObject.ok || dialogObject.cancel ? (
            <DialogActions sx={{ justifyContent: 'center' }}>
              {/* <hr /> */}
              <Stack
                spacing={2}
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="secondary"
                >
                  {dialogObject.cancel}
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="containedBlue"
                  //   color='primary'
                >
                  {dialogObject.ok}
                </Button>
              </Stack>
            </DialogActions>
          ) : null}
        </Box>
      </Dialog>
    </div>
  );
};

export default DialogPopupContainer;
