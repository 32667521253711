import React from 'react';

// Mui
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Filter({ categories, selected = false, setSelected, type }) {
  return (
    <Stack spacing={2}>
      <Typography variant="runningText1"> סננו על פי {type}</Typography>
      <Box sx={{ position: 'relative' }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{type}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected}
            label="Age"
            onChange={(e) => setSelected(e.target.value)}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 450 },
              },
            }}
          >
            {categories.map(
              (category) =>
                category && (
                  <MenuItem
                    key={type != 'קבוצה' ? category : category.id}
                    value={category}
                  >
                    {type != 'קבוצה' ? category : category.title.rendered}
                  </MenuItem>
                ),
            )}
          </Select>
        </FormControl>
        {selected && (selected.length || selected.id) > 0 && (
          <CloseIcon
            onClick={() => setSelected('')}
            sx={{
              position: 'absolute',
              right: '35px',
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
            }}
          />
        )}
      </Box>
    </Stack>
  );
}

export default Filter;
