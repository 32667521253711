import { useContext, useEffect, useState } from 'react';
import AppContext from '../contexts/AppContext';
import AlertContext from '../contexts/AlertContext';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchPostById } from '../api/api.js';
import { handlingAfterFetch } from '../helpers/Tools';

import LogoLoader from '../components/LogoLoader.jsx';
import Yad2Card from '../components/Yad2/Yad2Card.jsx';
import Yad2ImagesPopup from '../components/Yad2/Yad2ImagesPopup.jsx';

// Mui
import theme from '../styles/CustomTheme';
import { Box, Stack, Button } from '@mui/material/';

function Yad2SinglePage() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [post, setPost] = useState(false);
  const { setAlertObject } = useContext(AppContext);
  const [popup, setPopup] = useState(false);
  const [popupImages, setPopupImages] = useState([]);

  const fetchPost = async () => {
    await handlingAfterFetch(
      fetchPostById(id, 'yad2'),
      (res) => {
        if (res.status === 200) {
          setPost(res.fields);

          setPopupImages(res.fields.acf.gallery);
        }
      },
      setAlertObject,
    );
  };

  useEffect(() => {
    fetchPost();
  }, [id, location.pathname]);

  return (
    <Stack
      sx={{
        minHeight: '60vh',
        paddingBottom: '50px',
      }}
      alignItems="center"
    >
      {post ? (
        <Yad2Card
          post={post}
          expanded={true}
          locked={true}
          hideCloseButton={true}
          openPopup={setPopup}
        />
      ) : (
        <LogoLoader />
      )}
      {popup && (
        <Yad2ImagesPopup popupImages={popupImages} setPopup={setPopup} />
      )}
      <Button
        variant="containedLightBlue"
        onClick={() => navigate('/yad2')}
        sx={{
          width: 'fit-content',
          marginInline: 'auto',
        }}
      >
        לכלל החפצים
      </Button>
    </Stack>
  );
}

export default Yad2SinglePage;
