import React, { useEffect, useContext, memo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { fetchUsersByName } from '../api/api.js';
import { handlingAfterFetch } from '../helpers/Tools.js';
import LogoLoader from '../components/LogoLoader.jsx';
import AlertContext from '../contexts/AlertContext';

import UserContext from '../contexts/UserContext.js';
import Filter from '../components/Phonebook/Filter.jsx';

// Mui
import {
  Button,
  Container,
  Stack,
  Typography,
  TextField,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../styles/CustomTheme';
import UserCard from '../components/Phonebook/UserCard.jsx';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

const useStyles = makeStyles((theme) => ({
  mainTitleWrapper: {
    backgroundColor: `${theme.palette.primary.main}`,
    padding: '25px 15px',
    borderRadius: '30px 30px 0px 0px',
    // marginBottom: '30px',
  },

  marginBottom: {
    marginBottom: '30px',
  },

  mainTitle: {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: 1.1,
    color: `${theme.palette.my_white.main}`,
  },
}));

const Phonebook = memo(function Phonebook() {
  const { setAlertObject } = useContext(AlertContext);
  const { userFields } = useContext(UserContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('id');

  const [users, setUsers] = useState(undefined);
  const [filteredUsers, setFilteredUsers] = useState(undefined);
  const [branches, setBranches] = useState(undefined);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [departments, setDepartments] = useState(undefined);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [string, setString] = useState('');
  const [visibleItems, setVisibleItems] = useState(20);
  const [itemsPerPage] = useState(20);

  const classes = useStyles();

  const getAllUsers = async () => {
    if (!users)
      await handlingAfterFetch(
        fetchUsersByName(''),
        (res) => {
          if (res.status === 200 && res.users) {
            setUsers(res.users);
            setFilteredUsers(res.users);
            setBranches([...new Set(res.users.map((user) => user.branch))]);
            setDepartments([
              ...new Set(res.users.map((user) => user.department)),
            ]);
          } else {
            alert('Some problem with Getting users');
          }
        },
        setAlertObject,
      );
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (users)
      setFilteredUsers(
        users.filter((user) => {
          const branchFilter =
            selectedBranch && selectedBranch.length > 0
              ? user.branch === selectedBranch
              : true;
          const departmentFilter =
            selectedDepartment && selectedDepartment.length > 0
              ? user.department === selectedDepartment
              : true;
          const stringFilter =
            string && string.length > 0
              ? user.name.toLowerCase().includes(string.toLowerCase())
              : true;

          // Return true only if all filters pass
          return branchFilter && departmentFilter && stringFilter;
        }),
      );
  }, [selectedBranch, selectedDepartment, string, users]);

  useEffect(() => {
    if (!users) return;

    if (userId) {
      let user = users.find((user) => +user.id === +userId);
      if (user) {
        setFilteredUsers([user]);
        setString(user.name);
      }
    }
  }, [location.pathname, users]);

  const loadMore = () =>
    setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);

  return (
    <Container
      sx={{
        marginBlock: '25px',
        paddingBlock: '25px',
      }}
      className="rounded"
    >
      {users && branches && departments ? (
        <>
          <Box
            className={`page-title ${classes.mainTitleWrapper}`}
            sx={{ marginBottom: '10px' }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                component="h1"
                variant="h1"
                className={classes.mainTitle}
              >
                עובדי טמפו
              </Typography>
            </Stack>
          </Box>

          <Stack
            spacing={1}
            mb={4}
            mt={3}
            direction={{ sm: 'row' }}
            justifyContent="center"
            sx={{
              '@media (max-width: 769px)': {
                marginInline: '25px',
              },
              '& > *': {
                width: { sm: '30%' },
              },
            }}
          >
            {branches && (
              <Filter
                categories={branches}
                setCategories={setBranches}
                selected={selectedBranch}
                setSelected={setSelectedBranch}
                type="סניף"
              />
            )}
            {departments && (
              <Filter
                categories={departments}
                setCategories={setDepartments}
                selected={selectedDepartment}
                setSelected={setSelectedDepartment}
                type="יחידה אירגונית"
              />
            )}
            <Stack>
              <Typography variant="runningText1" sx={{ marginBottom: '15px' }}>
                חפשו על פי שם
              </Typography>
              <TextField
                className="form-input"
                placeholder="חיפוש עובדים"
                onChange={(e) => setString(e.target.value)}
                value={string}
                sx={{
                  width: '100%',
                  marginInline: 'auto',
                }}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <PersonSearchIcon position="end" />
                    </React.Fragment>
                  ),
                }}
              />
            </Stack>
          </Stack>
          <Stack
            direction={{ sm: 'row' }}
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="center"
          >
            {filteredUsers && filteredUsers.length ? (
              filteredUsers.slice(0, visibleItems).map((user) => {
                return <UserCard user={user} key={user.id} />;
              })
            ) : (
              <Typography
                variant="runningText3"
                component="h3"
                align="center"
                sx={{
                  // width: '100%',
                  background: theme.palette.secondary.dark,
                  padding: '10px',
                }}
              >
                לא נמצאו עובדים
              </Typography>
            )}
          </Stack>
          {visibleItems < filteredUsers?.length && (
            <Button
              onClick={loadMore}
              variant="containedBlue"
              sx={{
                marginInline: 'auto',
                display: 'block',
              }}
            >
              הצג עוד
            </Button>
          )}
        </>
      ) : (
        <LogoLoader />
      )}
    </Container>
  );
});

export default Phonebook;
