import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoLoader from '../components/LogoLoader.jsx';
import AlertContext from '../contexts/AlertContext';

// Mui
import theme from '../styles/CustomTheme';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Box,
  Link,
  Stack,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';

// functions
import { fetchPage } from '../api/api.js';
import { handlingAfterFetch } from '../helpers/Tools.js';

const useStyles = makeStyles((theme) => ({
  mainTitleWrapper: {
    backgroundColor: `${theme.palette.primary.main}`,
    padding: '25px 15px',
    borderRadius: '30px 30px 0px 0px',
    // marginBottom: '30px',
  },

  marginBottom: {
    marginBottom: '30px',
  },

  mainTitle: {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: 1.1,
    color: `${theme.palette.my_white.main}`,
  },
}));

function Lomda() {
  const classes = useStyles();
  const { setAlertObject } = useContext(AlertContext);
  const [pageInfo, setPageInfo] = useState(undefined);
  const location = useLocation();
  const getPageInfo = async (page_id) => {
    await handlingAfterFetch(
      fetchPage(page_id),
      (res) => {
        if (res.status === 200 && res.fields) {
          setPageInfo(res.fields);
        } else {
          alert('Some problem with Getting Page info');
        }
      },
      setAlertObject,
    );
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageId = queryParams.get('id');
    getPageInfo(pageId);
  }, [location.search]);

  return (
    <Container sx={{ marginBlock: '50px' }} className="rounded">
      {pageInfo ? (
        <>
          <Box
            className={`page-title ${classes.mainTitleWrapper} ${classes.marginBottom}`}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                component="h1"
                variant="h1"
                className={classes.mainTitle}
              >
                {pageInfo.title.rendered}
              </Typography>
            </Stack>
          </Box>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            flexWrap="wrap"
            spacing={3}
            useFlexGap
          >
            {pageInfo.acf?.lomdot &&
              pageInfo.acf?.lomdot?.map((lomda) => (
                <Link
                  href={lomda.link}
                  target="_blank"
                  underline="none"
                  sx={{
                    width: {
                      xs: '95%',
                      sm: '30%',
                      marginInline: 'auto',
                    },
                  }}
                >
                  <Card>
                    <CardMedia
                      sx={{
                        aspectRatio: 1.735,
                      }}
                      image={lomda?.image?.url}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="subtitle"
                        component="div"
                        align="center"
                      >
                        {lomda.title}
                      </Typography>
                      <Typography
                        align="center"
                        gutterBottom
                        variant="runningText1"
                        component="div"
                      >
                        {lomda.text}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              ))}
          </Stack>
        </>
      ) : (
        <LogoLoader />
      )}
    </Container>
  );
}

export default Lomda;
