import React from 'react';
import {
  Box,
  Button,
  Avatar,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Input,
  Link,
  Typography,
  CardActions,
  Stack,
  TextField,
  InputAdornment,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Grow from '@mui/material/Grow';
import moment from 'moment';

function UserCard({ user }) {
  return (
    <Grow in={true}>
      <Card
        sx={{
          padding: '25px',
          width: { xs: '85%', sm: '30%' },
          marginInline: {
            xs: 'auto',
            sm: '25px',
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        className="user-card"
        data-id={user.id}
      >
        <Avatar
          alt={user.name}
          src={user.avatar.url}
          sx={{
            width: '150px',
            height: '150px',
            marginInline: 'auto',
          }}
        />
        <CardContent sx={{ width: 'fit-content' }}>
          <Typography gutterBottom variant="h5" component="div" color="primary">
            {user.name}
          </Typography>
          <Stack direction="row">
            <Typography variant="runningText2">מחלקה: &nbsp;</Typography>
            <Typography variant="runningText2">{user.department}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="runningText2">סניף: &nbsp;</Typography>
            <Typography variant="runningText2">{user.branch}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="runningText2">יום הולדת: &nbsp;</Typography>
            <Typography variant="runningText2">
              {moment(user.birthday, 'DD/MM/YYYY').format('DD/MM')}
            </Typography>
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: 'space-around',
            width: '100%',
          }}
        >
          {user.email && (
            <Link
              href={'mailto:' + user.email}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <EmailIcon />
              {/* <Typography>{user.email}</Typography> */}
            </Link>
          )}
          {user.mobile && (
            <Link
              href={'tel:' + user.mobile}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <LocalPhoneIcon />
              {/* <Typography>{user.mobile}</Typography> */}
            </Link>
          )}
        </CardActions>
      </Card>
    </Grow>
  );
}

export default UserCard;
