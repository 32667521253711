import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as TempoLogo } from '../assets/images/tempo-logo.svg';

function LogoLoader() {
  return (
    <Box
      sx={{
        textAlign: 'center',
        marginBlock: '20px',
        '> svg': {
          width: '70px',
          height: '70px',
          animation: 'rotate 2s forwards infinite',
          animationTimingFunction: 'linear',
        },
      }}
    >
      <TempoLogo />
    </Box>
  );
}

export default LogoLoader;
