import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Flip } from 'react-toastify';

export const alertMessage = (message, type, autoClose = 5000) => {
  toast[`${type}`](message, {
    position: 'top-right',
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    transition: Flip,
    style: {
      width: 'min(375px, 90%)',
      marginBottom: '8px',
      top: '5px',
      direction: 'rtl',
    },
  });
};
