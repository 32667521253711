import React, { useState } from 'react';

import clsx from 'clsx';

// Swiper

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// Mui
import theme from '../../styles/CustomTheme';
import Avatar from '@mui/material/Avatar';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import { Collapse, Button, Stack, Typography, Box, Grow } from '@mui/material';
import { Link } from 'react-router-dom';
import { FacebookShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, WhatsappIcon } from 'react-share';

function Yad2Card({
  post,
  expanded = true,
  locked,
  openPopup,
  hideCloseButton,
}) {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [expand, setExpand] = useState(expanded);
  const [showShare, setShowShare] = useState(false);

  function handleCardClick(e) {
    if (locked) return;

    if (
      e.target.classList.contains('image') ||
      e.target.classList.contains('show-more') ||
      e.target.tagName === 'A' ||
      e.target.tagName === 'BUTTON' ||
      e.target.tagName === 'SVG' ||
      e.target.closest('.share-wrapper')
    ) {
      return;
    }
    setExpand(!expand);
  }

  return (
    <Box
      sx={{
        paddingBlock: '60px 30px',
        width: 'min(260px, 100%)',
        marginInline: 'auto',
      }}
      onClick={(e) => {
        handleCardClick(e);
      }}
    >
      <Collapse orientation="vertical" in={expand}>
        <Grow orientation="vertical" in={expand}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            useFlexGap
            sx={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '150px 150px',
              // padding: '0x 25px',
              width: '100%',
              color: 'white',
              gap: '13px',
              marginInline: 'auto',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{ position: 'relative' }}
              onClick={() => openPopup(post.acf.gallery)}
            >
              {!hideCloseButton && (
                <CloseIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpand(false);
                  }}
                  className="close-icon"
                  sx={{
                    position: 'absolute',
                    top: '5px',
                    left: '50%',
                    borderRadius: '100vw',
                    backgroundColor: theme.palette.background.overlay,
                    transform: 'translateX(-50%)',
                  }}
                />
              )}
              <img
                className="image"
                style={{
                  //   height: '100%',
                  width: '100%',
                  aspectRatio: '1.1',
                  objectFit: 'cover',
                  //   border: '2px solid white',
                }}
                src={post?.acf?.gallery?.length && post.acf.gallery[0].url}
                alt="post avatar"
              />
              <ZoomInIcon
                className="show-more"
                sx={{
                  position: 'absolute',
                  bottom: '17px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  cursor: 'pointer',
                }}
              />
            </Box>
            <Stack
              sx={{
                padding: '0 0 50px 0',
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  color: 'white!important',
                  typography: {
                    xs: 'h3',
                    sm: 'subtitle',
                  },
                }}
              >
                {post?.name}
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ padding: '10px 18px' }}
              >
                <Stack direction="row">
                  <FmdGoodOutlinedIcon />
                  <Typography>{post?.acf?.adress}</Typography>
                </Stack>
                {/* {} */}
                <KeyboardArrowDownIcon
                  className="show-more"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMoreInfo(!showMoreInfo);
                  }}
                  sx={{
                    transition: 'all 0.3s ease-in-out',
                    transform: showMoreInfo ? 'rotate(180deg)' : 'rotate(0deg',
                  }}
                />
              </Stack>

              <Collapse orientation="vertical" in={showMoreInfo}>
                <Typography
                  variant="p"
                  className={clsx('info-text', showMoreInfo && 'expanded')}
                  sx={{
                    padding: '10px 18px',
                  }}
                >
                  {post.acf.description}
                </Typography>
              </Collapse>

              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.10)',
                  padding: '10px 18px',
                }}
              >
                <Avatar
                  src={post.owner_avatar}
                  sx={{
                    width: '30px',
                    height: '30px',
                    marginInlineEnd: '10px',
                  }}
                />
                <Typography>
                  {post.owner_name} {', '} {post.owner_department}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                sx={{ padding: '10px 18px' }}
              >
                <a
                  href={'mailto:' + post.owner_email}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    variant="outlineLight"
                    color="my_white"
                    sx={{
                      width: 'fit-content',
                      whiteSpace: 'nowrap',
                      fontSize: '13px',
                    }}
                  >
                    {` שלח מייל`}
                  </Button>
                </a>
                <a
                  href={'tel:' + post.acf.phone}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    variant="containedWhite"
                    // color='my_white'
                    sx={{
                      width: 'fit-content',
                      whiteSpace: 'nowrap',
                      fontSize: '13px',
                    }}
                  >
                    התקשר/י
                  </Button>
                </a>
              </Stack>
              <Stack
                className="share-wrapper"
                direction="row"
                justifyContent="center"
                spacing={2}
                sx={{ cursor: 'pointer' }}
              >
                {showShare && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    useFlexGap
                    sx={{
                      position: 'absolute',
                      // right: '0',
                      transform: 'translateX(-100%)',
                    }}
                  >
                    <FacebookShareButton
                      url={`${window.location.origin}/yad2/${post.id}`}
                      style={{ height: '24px' }}
                    >
                      <FacebookIcon size={24} round={true}></FacebookIcon>
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={`${window.location.origin}/yad2/${post.id}`}
                      style={{ height: '24px' }}
                    >
                      <WhatsappIcon size={24} round={true} />
                    </WhatsappShareButton>
                  </Stack>
                )}
                <ShareOutlinedIcon
                  sx={{ fill: 'white' }}
                  onClick={() => setShowShare(!showShare)}
                />
                <Link
                  to={`https://api.whatsapp.com/send?phone=972${post.acf.phone}`}
                >
                  <WhatsAppIcon />
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Grow>
      </Collapse>
      <Collapse orientation="vertical" in={!expand}>
        <Grow orientation="vertical" in={!expand}>
          <Stack alignItems="center" spacing={4}>
            <Box
              sx={{
                width: '200px',
                borderRadius: '100vw',
                aspectRatio: '1',
                overflow: 'hidden',
              }}
            >
              <img
                src={post?.acf?.gallery?.length && post.acf.gallery[0].url}
                alt="item image"
                style={{
                  objectFit: 'cover',
                  height: '100%',
                }}
              />
            </Box>
            <Stack alignItems="center">
              <Typography
                variant="h3"
                color={theme.palette.primary.main}
                align="center"
              >
                {post.name}
              </Typography>
              <Typography variant="p" color={theme.palette.primary.main}>
                {post.acf.adress}
              </Typography>
            </Stack>
          </Stack>
        </Grow>
      </Collapse>
    </Box>
  );
}

export default Yad2Card;
