import theme from '../styles/CustomTheme';
import EllipseLoading from '../assets/images/loading_ellipse.png';
import { Box, Typography } from '@mui/material/';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  bubble_container1: {
    pointerEvents: 'none',
    position: 'absolute',
    // overflow: 'hidden',
    height: '200px',
    width: '200px',
    backgroundColor: 'transparent',
    left: '0',
    top: '0',
    '& $bubble': {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      border: `2px solid ${theme.palette.secondary.main}`,
      position: 'absolute',
      left: '0',
      bottom: '10%',
      animation: '$floatUp1 2s ease-in-out infinite',
    },
    '& $bubble2': {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      left: '5px',
      transform: 'translateX(100%)',
      animation: '$floatUp2 3s ease-in-out infinite',
    },
    '& $bubble3': {
      width: '25px',
      height: '25px',
      borderRadius: '50%',
      backgroundColor: '#2ecc71',
      position: 'absolute',
      left: '50%',
      animation: '$floatUp3 4s ease-in-out infinite',
    },
  },

  bubble: {}, // Empty rule for bubble, to be filled by container styles
  bubble2: {}, // Empty rule for bubble2, to be filled by container styles
  bubble3: {}, // Empty rule for bubble3, to be filled by container styles
  '@keyframes floatUp1': {
    '0%': {
      transform: 'translateY(0) translateX(-100%)',
      opacity: 1,
    },
    '50%': {
      transform: 'translateY(-100%) translateX(50px)',
      opacity: 0.8,
    },
    '100%': {
      transform: 'translateY(-180%) translateX(-100%)',
      opacity: 0.2,
    },
  },
  '@keyframes floatUp2': {
    '0%': {
      transform: 'translateY(100%) translateX(-100%)',
      opacity: 1,
    },
    '50%': {
      transform: 'translateY(-50%) translateX(25px) scale(1.9)',
      backgroundColor: theme.palette.primary.light,
      opacity: 0.8,
    },
    '100%': {
      transform: 'translateY(-100%) translateX(0) scale(1.3)',
      backgroundColor: theme.palette.primary.dark,
      opacity: 0,
    },
  },
  '@keyframes floatUp3': {
    '0%': {
      transform: 'translateY(0) translateX(-100%)',
      opacity: 0,
    },
    '50%': {
      transform: 'translateY(-50%) translateX(25px)',
      opacity: 0.8,
    },
    '100%': {
      transform: 'translateY(-100%) translateX(0)',
      opacity: 0,
    },
  },
}));

function Home() {
  const classes = useStyles();
  const LoadingContainer = {
    background: theme.palette.primary.main,
    width: '100%',
    minHeight: '100vh',
  };
  const InertContainer = {
    display: 'flex',
    gap: '27px',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const Title = {
    color: 'white',
    maxWidth: '200px',
    textAlign: 'center',
    fontSize: '48px',
  };

  return (
    <Box sx={LoadingContainer}>
      <Box sx={InertContainer}>
        <Box className={classes.bubble_container1}>
          <Box className={classes.bubble}></Box>
          <Box className={classes.bubble2}></Box>
          <Box className={classes.bubble3}></Box>
        </Box>
        <Typography component="h1" variant="h1" sx={Title}>
          כבר מתחילים
        </Typography>
      </Box>
    </Box>
  );
}

export default Home;
