import React from 'react';

import { ERORR_ALERT_OBJECT, WARNING_ALERT_OBJECT } from './AlertPopup';

export const handlingAfterFetch = async (
  fetch,
  success_handler,
  setAlertObject,
) => {
  try {
    const result = await fetch;

    if (result.status === 200 || result.status === 201) {
      if (success_handler) success_handler(result);
      //   if (result.status) {
      //   } else {
      //     setAlertObject &&
      //       setAlertObject({ ...ERORR_ALERT_OBJECT, message: result.message });
      //   }
    } else if (result.status === 401) {
      // unautorized
      setAlertObject &&
        setAlertObject({
          ...WARNING_ALERT_OBJECT,
          severity: 'warning',
          message: result.message,
        });
    } else {
      setAlertObject &&
        setAlertObject({
          ...ERORR_ALERT_OBJECT,
          message: result.message,
        });
    }
  } catch (error) {
    console.log('🚀 ~ file: Tools.js:29 ~ error:', error);
    setAlertObject &&
      setAlertObject({
        ...ERORR_ALERT_OBJECT,
        message: `Unexpected error: ${error}`,
      });
    return false;
  }
};

export const generateUniqueId = (prefix = '', length = 13) => {
  const timestamp = Date.now().toString(36);
  const randomStr = Math.random().toString(36).substr(2, length);
  return `${prefix}${timestamp}${randomStr}`;
};
