export const RegisterButton = (theme) => ({
  width: '100%',
  padding: '8px 15px',
  letterSpacing: '0.7px',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '1.15',

  '&:disabled': {
    color: theme.palette.my_white.main,
    backgroundColor: theme.palette.primary.dark50,
    padding: '8px 12px',
  },
});

export const TransportationButton = (theme) => ({
  color: theme.palette.button.main,
  borderRadius: '30px',
  padding: '8px 15px',
  letterSpacing: '0.7px',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '1.15',
  border: `1px ${theme.palette.primary.main} solid`,
});

export const ApproveArrivmentButton = (theme) => ({
  padding: '8px 15px',
  letterSpacing: '0.7px',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '1.15',

  '&:disabled': {
    color: theme.palette.my_white.main,
    backgroundColor: theme.palette.green.button,
    border: 'unset',
  },
});
