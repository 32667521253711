import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import ThankYouBg from '../../assets/images/thank-you-bg.png';
import { handlingAfterFetch } from '../../helpers/Tools';
import UserContext from '../../contexts/UserContext';
import AlertContext from '../../contexts/AlertContext';

import { fetchPostById, sendSurveyAnswers } from '../../api/api';
import { ReactComponent as Radio } from '../../assets/images/radio/radio.svg';
import { ReactComponent as RadioSelected } from '../../assets/images/radio/radio-selected.svg';
import { ReactComponent as BackArrow } from '../../assets/images/back-arrow.svg';
import { ReactComponent as BackArrowBlue } from '../../assets/images/back-arrow-blue.svg';
import { makeStyles } from '@mui/styles';
import SurveySliderItem from './SurveySliderItem';
import { alertMessage } from '../../helpers/AlertMessage';
import LogoLoader from '../LogoLoader.jsx';
import { useNavigate } from 'react-router-dom';
import theme from '../../styles/CustomTheme';

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    backgroundColor: '#FAFBFE',
    borderRadius: '40px',
  },

  surveyImage: {
    position: 'absolute',
    // inset: 0,
    borderRadius: '100vw !important',
    backgroundColor: '#0a1d75',
    boxSizing: 'content-box',

    [theme.breakpoints.down('sm')]: {
      width: '270px !important',
      height: '270px',
      top: '100%!important',
      padding: '20px',
      border: '20px solid #0c2380',
      maxHeight: 'unset',
    },
    [theme.breakpoints.up('sm')]: {
      width: '400px !important',
      height: '400px',
      padding: '40px',
      border: '40px solid #0c2380',
    },
    height: '98.5%',
  },

  bgImage: {
    position: 'relative',
    borderRadius: '40px 40px 0px 0px',
    width: '100%',
    zIndex: 1,
  },

  mainTitle: {
    color: theme.palette.primary.dark,
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: 1.093,
    marginBlock: '40px 30px',
  },

  selectInput: {
    borderRadius: '30px',
    backgroundColor: theme.palette.my_white.main,
    boxShadow: '0px 0px 30px 0px rgba(2, 37, 166, 0.05)',
    padding: '15px 20px',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1,
    width: '99%',
  },

  label: {
    flexGrow: 1,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
  },

  colorDark: {
    color: theme.palette.primary.dark,
  },

  textarea: {
    borderRadius: '10px',
    background: theme.palette.primary.extra_light,
    resize: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '10px',
    color: theme.palette.primary.main,
    outline: 'none',
    fontSize: '16px',

    '&::placeholder': {
      opacity: 1,
      color: theme.palette.primary.dark,
    },
  },

  sendButton: {
    fontSize: '18px',
    lineHeight: 1,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },

  thankYouTitle: {
    color: theme.palette.primary.dark,
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: 1.093,
    marginBlock: '40px 10px',
    textAlign: 'center',
  },

  thankYouMessage: {
    color: theme.palette.primary.dark,
    fontSize: '25px',
    fontWeight: 400,
    lineHeight: 1.093,
    marginBlock: '0 40px',
    textAlign: 'center',
  },

  thankYouButtons: {
    padding: '8px 15px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: '0.7px',
  },
  bubble_container1: {
    pointerEvents: 'none',
    position: 'absolute',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
    left: '0',
    top: '0',
    zIndex: '2',
    '& $bubble': {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      border: `2px solid ${theme.palette.secondary.main}`,
      position: 'absolute',
      left: '25%',
      top: '95%',
      animation: '$floatUp1 8s ease-in-out infinite',
    },
    '& $bubble2': {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      left: '25%',
      transform: 'translateX(100%)',
      animation: '$floatUp2 10s ease-in-out infinite',
    },
    '& $bubble3': {
      width: '25px',
      height: '25px',
      borderRadius: '50%',
      backgroundColor: '#2ecc71',
      position: 'absolute',
      left: '50%',
      animation: '$floatUp3 6s ease-in-out infinite',
    },
  },

  bubble: {}, // Empty rule for bubble, to be filled by container styles
  bubble2: {}, // Empty rule for bubble2, to be filled by container styles
  bubble3: {}, // Empty rule for bubble3, to be filled by container styles
  '@keyframes floatUp1': {
    '0%': {
      transform: 'translateY(0) translateX(-100%)',
      opacity: 0,
    },
    '50%': {
      transform: 'translateY(-100%) translateX(50px)',
      opacity: 0.8,
      left: '60%',
    },
    '100%': {
      transform: 'translateY(-250%) translateX(-100%)',
      top: '0%',
      left: '25%',
      opacity: 0,
    },
  },
  '@keyframes floatUp2': {
    '0%': {
      transform: 'translateY(100%) translateX(-100%)',
      bottom: '0%',
      opacity: 0,
    },
    '50%': {
      transform: 'translateY(-50%) translateX(25px) scale(1.9)',
      backgroundColor: theme.palette.primary.light,
      opacity: 1,
      bottom: '40%',
    },
    '100%': {
      transform: 'translateY(-100%) translateX(0) scale(1.3)',
      backgroundColor: theme.palette.primary.dark,
      opacity: 0,
      left: '10%',
      bottom: '100%',
    },
  },
  '@keyframes floatUp3': {
    '0%': {
      //   transform: 'translateY(0) translateX(100%)',
      opacity: 0,
      right: '0',
      bottom: '15%',
    },
    '50%': {
      //   transform: 'translateY(-50%) translateX(25px)',
      right: '40%',
      opacity: 0.8,
      bottom: '60%',
    },
    '100%': {
      //   transform: 'translateY(-100%) translateX(0)',
      right: '10%',
      opacity: 0,
      bottom: '100%',
    },
  },
}));

function Survey({ id }) {
  const [post, setPost] = useState({});
  const [surveyAnswers, setSurveyAnswers] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const { setAlertObject } = useContext(AlertContext);
  const { loggedInUser } = useContext(UserContext);

  const navigate = useNavigate();

  const classes = useStyles(); // Initialize useStyles

  const sanitizeTitle = (title) => title.replace(/[^\u0590-\u05FF\w]+/g, '_');

  const handleFetchPost = async () => {
    await handlingAfterFetch(
      fetchPostById(id, 'survey'),
      (res) => {
        if (res.status === 200) {
          const tmpObj = {};

          res?.fields.acf.survey_fields?.forEach((field, index) => {
            // if (field.type === 'select') {
            // 	tmpObj[sanitizeTitle(field.title)] = [];
            // } else tmpObj[sanitizeTitle(field.title)] = '';
            // if (field.type === 'select') {
            // 	tmpObj[sanitizeTitle(field.title)] = [];
            // } else if (field.type === 'radio') {
            // 	tmpObj[sanitizeTitle(field.title)] = '';
            // } else if (
            // 	field.type === 'open' ||
            // 	field.type === 'area'
            // ) {
            // 	tmpObj[sanitizeTitle(field.title)] = '';
            // }
            let value = [];
            if (field.type === 'open' || field.type === 'area') {
              if (field?.answers?.length > 0) {
                field?.answers.forEach((answer) => {
                  value.push({
                    type: field.type,
                    title: sanitizeTitle(`${answer.name}${index}`),
                    value: '',
                  });
                });
              }
            } else if (field.type === 'radio') {
              value = [
                {
                  type: field.type,
                  value: '',
                },
              ];
            } else if (field.type === 'select') {
              // value.push(field.type);
              // field?.answers &&
              field?.answers.forEach((answer) => {
                value.push({
                  id: answer.id,
                  value: '',
                });
              });
            }
            tmpObj[sanitizeTitle(field.title)] = value;
          });
          setSurveyAnswers(tmpObj);
          setPost(res?.fields?.acf);
          setShowLoader(false);
        } else {
          alertMessage('שגיאה פנימית', 'error');
          setShowLoader(false);
        }
      },
      setAlertObject,
    );
  };

  const handleChangeAnswer = (title, answer, type, id) => {
    const tempObj = { ...surveyAnswers };
    if (type === 'select' || type === 'radio') {
      // if (tempObj[title].includes(answer)) {
      // 	tempObj[title] = tempObj[title].filter(
      // 		(selected) => selected !== answer,
      // 	);
      // } else tempObj[title].push(answer.replace(/\n/g, ' '));
      tempObj[title].find((obj) => {
        if (obj?.id === id) {
          obj.value ? (obj.value = '') : (obj.value = answer);
        }
      });
    } else tempObj[title][0].value = answer.replace(/\n/g, ' ');

    setSurveyAnswers(tempObj);
  };

  const handleSendData = async () => {
    let helper = currentQuestion;
    Object.keys(surveyAnswers).forEach((answer) => {
      surveyAnswers[answer].forEach((a) => {
        if (a.value) helper--;
      });
    });
    if (helper < 0) {
      if (currentQuestion + 1 < post?.survey_fields.length)
        setCurrentQuestion(currentQuestion + 1);
      else {
        await handlingAfterFetch(
          sendSurveyAnswers(id, surveyAnswers, loggedInUser.id),

          (res) => {
            if (res.status === 200) {
              setShowThankYou(true);
            } else {
              alertMessage('שגיאה פנימית', 'error');
              setShowLoader(false);
            }
          },
          setAlertObject,
        );
      }
    } else alertMessage('אנא מלא תשובה', 'warn');
  };

  useEffect(() => {
    handleFetchPost();
  }, []);

  return showLoader ? (
    <LogoLoader />
  ) : (
    <Stack
      className={`survey ${classes.cardStyle}`}
      direction={{
        xs: 'column',
        sm: 'row-reverse',
      }}
      alignItems="center"
    >
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: theme.palette.primary.dark,
          height: { sm: '530px', xs: '225px' },
          width: { sm: '50%', xs: '100%' },
          borderRadius: {
            xs: '40px 40px 0px 0px',
            sm: '0px 40px 40px 0px',
          },
        }}
      >
        <Box
          component="img"
          src={post?.survey_image}
          alt="Image"
          className={classes.surveyImage + ' absolute-center'}
        />
        <Box className={classes.bubble_container1}>
          <Box className={classes.bubble}></Box>
          <Box className={classes.bubble2}></Box>
          <Box className={classes.bubble3}></Box>
        </Box>
      </Box>
      <Stack sx={{ width: { xs: '100%', sm: '50%' } }}>
        {showThankYou ? (
          <>
            <Typography
              variant="h2"
              component="h2"
              className={classes.thankYouTitle}
            >
              תודה על המענה !
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              className={classes.thankYouMessage}
            >
              קיבלנו את המשוב שלך
            </Typography>

            <Stack
              direction="row"
              spacing={2.5}
              justifyContent="center"
              sx={{ paddingBottom: '40px' }}
            >
              <Button
                variant="containedRed"
                className={classes.thankYouButtons}
                onClick={() => navigate('/home-page')}
              >
                לדף הבית
              </Button>
              <Button
                variant="containedBlue"
                className={classes.thankYouButtons}
                onClick={() => navigate('/')}
              >
                לאיזור האישי
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Stack
              className="slider"
              direction="row"
              spacing={2}
              sx={{ margin: '40px 25px 0' }}
            >
              {post?.survey_fields?.map((slide, key) => {
                return (
                  <SurveySliderItem
                    key={key}
                    color={currentQuestion >= key ? 'blue' : 'white'}
                  />
                );
              })}
            </Stack>
            <Box className="fields" sx={{ marginInline: '25px' }}>
              {post?.survey_fields?.map((field, key) => {
                return (
                  key === currentQuestion && (
                    <React.Fragment key={key}>
                      <Typography
                        variant="h2"
                        component="h2"
                        className={classes.mainTitle}
                      >
                        {field?.title}
                      </Typography>
                      <Stack
                        spacing={2}
                        sx={{
                          paddingBottom: '30px',
                        }}
                        direction={{
                          xs: 'column',
                          sm: 'row',
                        }}
                        flexWrap="wrap"
                        useFlexGap
                      >
                        {field?.type === 'radio' &&
                          field?.answers?.map((answer, index) => {
                            return (
                              <Stack
                                className="radio-wrapper"
                                key={index}
                                direction="row"
                                alignItems="center"
                                sx={{
                                  width: {
                                    xs: '100%',
                                    sm: '45%',
                                  },
                                }}
                              >
                                <input
                                  type="radio"
                                  id={answer?.name}
                                  name={field?.title}
                                  onChange={() => {
                                    handleChangeAnswer(
                                      sanitizeTitle(field?.title),
                                      answer?.name,
                                      field?.type,
                                    );
                                  }}
                                  checked={
                                    answer?.name ===
                                    surveyAnswers[sanitizeTitle(field.title)][0]
                                  }
                                  style={{
                                    display: 'none',
                                  }}
                                />

                                <label
                                  htmlFor={answer?.name}
                                  className={classes.label}
                                >
                                  {answer?.name ===
                                  surveyAnswers[sanitizeTitle(field.title)][0]
                                    .value ? (
                                    <RadioSelected />
                                  ) : (
                                    <Radio />
                                  )}
                                  <span className={classes.selectInput}>
                                    {answer.name}
                                  </span>
                                </label>
                              </Stack>
                            );
                          })}
                        {field.type === 'area' && (
                          <Stack>
                            <textarea
                              className={classes.textarea}
                              id={field?.answer?.name}
                              rows="4"
                              placeholder={field?.answers[0].name}
                              value={
                                surveyAnswers[sanitizeTitle(field.title)][0]
                                  .value
                              }
                              onChange={(e) => {
                                handleChangeAnswer(
                                  sanitizeTitle(field?.title),
                                  e.target.value,
                                  field?.type,
                                );
                              }}
                            />
                          </Stack>
                        )}
                        {field.type === 'open' && (
                          <Stack>
                            <input
                              className={classes.textarea}
                              id={field?.answers[0]?.name}
                              placeholder={field?.answers[0].name}
                              value={
                                surveyAnswers[sanitizeTitle(field.title)][0]
                                  .value
                              }
                              onChange={(e) => {
                                handleChangeAnswer(
                                  sanitizeTitle(field?.title),
                                  e.target.value,
                                  field?.type,
                                );
                              }}
                            />
                          </Stack>
                        )}
                        {field?.type === 'select' &&
                          field?.answers?.map((answer, index) => {
                            return (
                              <Stack
                                className="checkbox-wrapper"
                                key={index}
                                direction="row"
                                alignItems="center"
                              >
                                <input
                                  type="checkbox"
                                  id={answer?.name}
                                  onClick={() => {
                                    handleChangeAnswer(
                                      sanitizeTitle(field?.title),
                                      answer?.name,
                                      field?.type,
                                      answer?.id,
                                    );
                                  }}
                                  checked={surveyAnswers[
                                    sanitizeTitle(field.title)
                                  ].includes(answer?.name)}
                                  style={{
                                    display: 'none',
                                  }}
                                />

                                <label
                                  htmlFor={answer?.name}
                                  className={classes.label}
                                >
                                  {
                                    // surveyAnswers[
                                    // 	sanitizeTitle(
                                    // 		field.title,
                                    // 	)
                                    // ].includes(
                                    // 	answer?.name,
                                    // )
                                    surveyAnswers[
                                      sanitizeTitle(field.title)
                                    ].find(
                                      (obj) => obj.value === answer?.name,
                                    ) ? (
                                      <RadioSelected />
                                    ) : (
                                      <Radio />
                                    )
                                  }
                                  <span className={classes.selectInput}>
                                    {answer.name}
                                  </span>
                                </label>
                              </Stack>
                            );
                          })}
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={2}
                        className="buttons-wrapper"
                        sx={{
                          paddingBottom: '40px',
                        }}
                      >
                        <Stack
                          className={classes.sendButton}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                          onClick={() => {
                            currentQuestion > 0 &&
                              setCurrentQuestion(currentQuestion - 1);
                          }}
                        >
                          <BackArrowBlue
                            style={{
                              scale: '-1',
                            }}
                          />
                          <span className={classes.colorDark}>הקודם</span>
                        </Stack>
                        <Stack
                          className={classes.sendButton}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                          onClick={() => handleSendData()}
                        >
                          <span>
                            {currentQuestion + 1 === post?.survey_fields.length
                              ? 'שלח'
                              : 'הבא'}
                          </span>
                          <BackArrow />
                        </Stack>
                      </Stack>
                    </React.Fragment>
                  )
                );
              })}
            </Box>
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default Survey;
