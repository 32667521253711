import { useContext, useEffect, useState } from 'react';
import AppContext from '../contexts/AppContext';
import AlertContext from '../contexts/AlertContext';

import { fetchPostById } from '../api/api.js';
import { useParams } from 'react-router-dom';
import { handlingAfterFetch } from '../helpers/Tools';

import LogoLoader from '../components/LogoLoader.jsx';

// mui
import { Box, Stack, Typography } from '@mui/material';
import theme from '../styles/CustomTheme';
import { Container } from '@mui/system';

function ContentPost() {
  const { id } = useParams();
  const { setAlertObject } = useContext(AlertContext);

  const [post, setPost] = useState(false);

  const fetchPost = async () => {
    await handlingAfterFetch(
      fetchPostById(id, 'posts'),
      (res) => {
        if (res.status === 200) {
          setPost(res.fields);
        }
      },
      setAlertObject,
    );
  };

  useEffect(() => {
    fetchPost();
  }, [id]);

  return (
    <Box>
      <Container sx={{ paddingTop: '25px' }}>
        {post ? (
          <>
            <Typography variant="mobileHeadline">
              {post.title.rendered}
              <Box
                sx={{
                  color: theme.palette.primary.main,
                  img: { height: 'auto' },
                }}
                dangerouslySetInnerHTML={{
                  __html: post?.content.rendered,
                }}
              ></Box>
              <Box
                sx={{
                  color: theme.palette.primary.main,
                  img: { height: 'auto' },
                }}
                dangerouslySetInnerHTML={{
                  __html: post?.acf.content,
                }}
              ></Box>
            </Typography>
          </>
        ) : (
          <LogoLoader />
        )}
      </Container>
    </Box>
  );
}

export default ContentPost;
