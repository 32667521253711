import { useContext, useEffect } from 'react';
import UserContext from '../contexts/UserContext';

import Home from '../screens/Home';

const ProtectedRoute = ({ children }) => {
  const { loggedInUser } = useContext(UserContext);

  useEffect(() => {}, []);

  if (loggedInUser.role !== 'administrator') {
    return <Home></Home>;
  }

  return children;
};

export default ProtectedRoute;
