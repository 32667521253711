import React, { useContext, useEffect, useState } from 'react';
import { handlingAfterFetch } from '../helpers/Tools';
import { fetchCart } from '../api/shopApi';
import AlertContext from './AlertContext';
import UserContext from './UserContext.js';

const CartContext = React.createContext({});
export default CartContext;

export const CartContextProvider = ({ children }) => {
  const { setAlertObject } = useContext(AlertContext);
  const [cartData, setCartData] = useState([]);
  const [orderEventDetails, setOrderEventDetails] = useState({
    for_event: '',
    event_type: '',
    relation: '',
  });

  const { loggedInUser } = useContext(UserContext);

  const handleSetEventDetails = () => {
    const match = document.cookie.match(
      new RegExp('(^| )event_details=([^;]+)'),
    );

    if (match) {
      setOrderEventDetails(JSON.parse(match[2]));
    }
  };

  const cartContextData = {
    cartData,
    setCartData,
    orderEventDetails,
    setOrderEventDetails,
  };

  const handleFetchCartData = async () => {
    await handlingAfterFetch(
      fetchCart(),
      (res) => {
        if (res.status === 200) {
          setCartData(res.fields);
        }
      },
      setAlertObject,
    );
  };

  useEffect(() => {
    if (loggedInUser) {
      handleFetchCartData();
      handleSetEventDetails();
    }
  }, [loggedInUser]);

  return (
    <CartContext.Provider value={cartContextData}>
      {children}
    </CartContext.Provider>
  );
};
