import React, { useEffect, useContext, memo, useState } from 'react';
import moment from 'moment';

import { useLocation } from 'react-router-dom';

import { useWindowSize } from '../helpers/useWindowSize.jsx';

import {
  fetchUsersByName,
  fetchAllPosts,
  updatePost,
  postPost,
  sendPushData,
  deletePost,
} from '../api/api.js';
import { alertMessage } from '../helpers/AlertMessage';
import { handlingAfterFetch } from '../helpers/Tools.js';
import LogoLoader from '../components/LogoLoader.jsx';
import AlertContext from '../contexts/AlertContext';
import AppContext from '../contexts/AppContext.js';
import Filter from '../components/Phonebook/Filter.jsx';

// Mui
import {
  Autocomplete,
  Avatar,
  Button,
  Container,
  Stack,
  Typography,
  TextField,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../styles/CustomTheme';
import UserCard from '../components/Phonebook/UserCard.jsx';
import SearchIcon from '@mui/icons-material/Search';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Grow from '@mui/material/Grow';

const useStyles = makeStyles((theme) => ({
  mainTitleWrapper: {
    backgroundColor: `${theme.palette.primary.main}`,
    padding: '25px 15px',
    borderRadius: '30px 30px 0px 0px',
    // marginBottom: '30px',
  },

  marginBottom: {
    marginBottom: '30px',
  },

  mainTitle: {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: 1.1,
    color: `${theme.palette.my_white.main}`,
  },
  input: {
    background: theme.palette.primary.extra_light,
    justifyContent: 'center',
    padding: '10px 15px',
    borderRadius: '100vw',
  },
}));

const PushNotifications = memo(function PushNotifications() {
  const [width, height] = useWindowSize();

  const { setLoading } = useContext(AppContext);
  const { setAlertObject, setDialogObject, alertObject } =
    useContext(AlertContext);

  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');
  const [users, setUsers] = useState(undefined);
  const [minAge, setMinAge] = useState(undefined);
  const [maxAge, setMaxAge] = useState(undefined);
  const [groups, setGroups] = useState(undefined);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [selectedGroupName, setSelectedGroupName] = useState(
    selectedGroup?.title?.rendered,
  );
  const [newGroupName, setNewGroupName] = useState('');
  const autocomplete_loading = open && users.length === 0;
  const [filteredUsers, setFilteredUsers] = useState(undefined);
  const [branches, setBranches] = useState(undefined);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [departments, setDepartments] = useState(undefined);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  // const [string, setString] = useState('');
  const [visibleItems, setVisibleItems] = useState(20);
  const [itemsPerPage] = useState(width > 768 ? 21 : 20);

  const classes = useStyles();

  const getAllUsers = async () => {
    if (!users)
      await handlingAfterFetch(
        fetchUsersByName(''),
        (res) => {
          if (res.status === 200 && res.users) {
            setUsers(res.users);

            setFilteredUsers(res.users);
            setBranches([...new Set(res.users.map((user) => user.branch))]);
            setDepartments([
              ...new Set(res.users.map((user) => user.department)),
            ]);
          } else {
            alert('Some problem with Getting users');
          }
        },
        setAlertObject,
      );
  };

  const getGroups = async (groupIdToSet) => {
    await handlingAfterFetch(
      fetchAllPosts('msg_group'),
      (res) => {
        if (res.status === 200 && res.fields) {
          setGroups(res.fields);
          // Set current group if needed
          if (groupIdToSet)
            setSelectedGroup(res.fields.find((g) => g.id === groupIdToSet));
        } else {
          alert('Some problem with Getting Groups');
        }
      },
      setAlertObject,
    );
  };
  const updateGroup = async () => {
    let newGroupObg = {
      acf: {
        users: filteredUsers.map((user) => user.id),
      },
    };

    setLoading(true);
    await handlingAfterFetch(
      updatePost(selectedGroup.id, newGroupObg, 'msg_group'),
      (res) => {
        if (res.status === 200 && res.fields) {
          getGroups(res.fields.id);
        } else {
          alert('Some problem with Getting Groups');
        }
        setLoading(false);
      },
      setAlertObject,
    );
  };
  const createGroup = async () => {
    if (!newGroupName || newGroupName.length === 0) {
      alertMessage('אנא בחר שם לקבוצה', 'warn');
      return;
    }
    let newGroupObg = {
      type: 'msg_group',
      status: 'publish',
      title: newGroupName,
      acf: {
        users: filteredUsers.map((user) => user.id),
      },
    };

    setLoading(true);
    await handlingAfterFetch(
      postPost(newGroupObg),
      (res) => {
        if (res.status === 200 && res.fields) {
          getGroups(res.fields.id);
        } else {
          alert('Some problem with Getting Groups');
        }
        setLoading(false);
      },
      setAlertObject,
    );
  };
  const handleRemoveGroup = async () => {
    let groupObg = {
      id: selectedGroup.id,
      type: 'msg_group',
    };

    setLoading(true);
    await handlingAfterFetch(
      deletePost(groupObg),
      (res) => {
        if (res.status === 200 && res.fields) {
          getGroups();
          setSelectedGroup(false);
        } else {
          alert('Some problem with Getting Groups');
        }
        setLoading(false);
      },
      setAlertObject,
    );
  };

  const updateGroupName = async () => {
    if (!selectedGroupName || selectedGroupName.length === 0) return;
    let newGroupObg = {
      title: selectedGroupName,
      // acf: {
      //   users: filteredUsers.map(
      //     (user) => user.id
      //   ),
      // },
    };
    setLoading(true);
    await handlingAfterFetch(
      updatePost(selectedGroup.id, newGroupObg, 'msg_group'),
      (res) => {
        if (res.status === 200 && res.fields) {
          getGroups(res.fields.id);
        } else {
          alert('Some problem with Getting Groups');
        }
        setLoading(false);
      },
      setAlertObject,
    );
  };

  function showGroupUsers() {
    let groupUsers = [];
    selectedGroup.acf.users.forEach((userId) => {
      groupUsers.push(users.find((userObj) => userObj.id === userId));
    });
    setFilteredUsers(groupUsers);
  }

  function removeUserFromList(id) {
    const updatedUsers = filteredUsers.filter((user) => user.id !== id);
    setFilteredUsers([...updatedUsers]);
  }

  const handleOptionSelect = (event, selectedOption) => {
    if (selectedOption) {
      // Check if the selected user exists in the array
      const userIndex = filteredUsers.findIndex(
        (user) => user.id === selectedOption.id,
      );

      if (userIndex !== -1) {
        // If the user exists, remove it from its current position
        // and add it to the start of the array
        const updatedUsers = [...filteredUsers];
        updatedUsers.splice(userIndex, 1);
        updatedUsers.unshift(selectedOption);
        setFilteredUsers([...updatedUsers]);
      } else {
        // If the user doesn't exist, add it to the start of the array
        setFilteredUsers([selectedOption, ...filteredUsers]);
      }
    }
  };
  const handleType = async (e) => {
    setOpen(true);
  };

  const handleSendData = async () => {
    setLoading(true);
    if (message.length > 2) {
      await handlingAfterFetch(
        sendPushData({
          message: message,
          filteredUsers: filteredUsers,
          link: link,
        }),
        (res) => {
          if (res.status === 200) {
            setLoading(false);
            alertMessage(' הודעה נשלחה', 'success');
          } else {
            alertMessage(res.fields.Message, 'error');
            setLoading(false);
          }
        },
        setAlertObject,
      );
    } else {
      alertMessage('אנא מלא הודעה', 'warn');
    }
  };

  useEffect(() => {
    getAllUsers();
    if (!groups) getGroups();
  }, []);

  useEffect(() => {
    if (users)
      setFilteredUsers(
        users.filter((user) => {
          const branchFilter =
            selectedBranch && selectedBranch.length > 0
              ? user.branch === selectedBranch
              : true;
          const departmentFilter =
            selectedDepartment && selectedDepartment.length > 0
              ? user.department === selectedDepartment
              : true;

          const now = moment();
          // if there is minAge set than filter accordingly
          const ageFilter =
            minAge || maxAge
              ? user.children &&
                user.children?.some((child) => {
                  const birthDate = moment(child.date_of_birth, 'YYYY-MM-DD');
                  const age = now.diff(birthDate, 'years'); // Calculate age
                  if (minAge && maxAge) {
                    return age >= +minAge && age <= +maxAge;
                  } else if (minAge) {
                    return age >= +minAge;
                  } else if (maxAge) {
                    return age <= +maxAge;
                  }
                })
              : true;

          return branchFilter && departmentFilter && ageFilter;
        }),
      );
  }, [selectedBranch, selectedDepartment, users, minAge, maxAge]);

  // On group change clear all filter abd filter according group
  useEffect(() => {
    if (selectedGroup) {
      setSelectedBranch('');
      setSelectedDepartment('');
      setFilteredUsers('');
      // Schedule showGroupUsers to run after the state updates
      setTimeout(() => {
        showGroupUsers();
      }, 0);
    } else {
      setFilteredUsers(users);
    }
  }, [selectedGroup]);

  const loadMore = () =>
    setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);

  return (
    <Container
      sx={{
        marginBlock: '25px',
        paddingBlock: '25px',
      }}
      className="rounded"
    >
      {users && branches && departments ? (
        <>
          <Box
            className={`page-title ${classes.mainTitleWrapper}`}
            sx={{ marginBottom: '10px' }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                component="h1"
                variant="h1"
                className={classes.mainTitle}
              >
                שליחת הודעות לעובדים
              </Typography>
            </Stack>
          </Box>
          {/* Filtering */}
          <Stack
            spacing={1}
            mb={4}
            direction={{ sm: 'row' }}
            justifyContent="center"
            sx={{
              '& > *': {
                width: { sm: '30%' },
              },
            }}
          >
            {branches && (
              <Filter
                categories={branches}
                setCategories={setBranches}
                selected={selectedBranch}
                setSelected={setSelectedBranch}
                type="סניף"
              />
            )}
            {departments && (
              <Filter
                categories={departments}
                setCategories={setDepartments}
                selected={selectedDepartment}
                setSelected={setSelectedDepartment}
                type="יחידה אירגונית"
              />
            )}
            <Stack>
              <Typography variant="runningText1" sx={{ marginBottom: '15px' }}>
                הוספה של עובדים בודדים
              </Typography>
              <Autocomplete
                id="asynchronous-demo"
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                onChange={handleOptionSelect}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                options={users}
                loading={autocomplete_loading}
                loadingText="טוען..."
                noOptionsText="לא נמצאו תוצאות"
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      '& > img': {
                        mr: 2,
                        flexShrink: 0,
                      },
                    }}
                    {...props}
                    key={option.id}
                  >
                    <Avatar
                      sx={{
                        marginInlineEnd: '10px',
                      }}
                      src={option?.avatar?.url}
                    />

                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="form-input"
                    placeholder="הוספת עובד מהרשימה"
                    onChange={handleType}
                    // sx={{ width: '90%', marginInline: 'auto' }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          <SearchIcon position="end" />
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>
          {/* Filter by kids age */}
          <Stack
            spacing={1}
            mb={4}
            direction={{ sm: 'row' }}
            //   justifyContent='center'
            sx={{
              maxWidth: '92%',
              marginInline: 'auto',
            }}
          >
            <Stack>
              <Typography variant="runningText1" sx={{ marginBottom: '15px' }}>
                סינון על פי גיל הילדים
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  placeholder="מגיל"
                  className={classes.input}
                  onChange={(event) => setMinAge(event.target.value)}
                />
                <Typography>עד</Typography>
                <TextField
                  className={classes.input}
                  placeholder="עד גיל"
                  onChange={(event) => setMaxAge(event.target.value)}
                />
              </Stack>
            </Stack>
          </Stack>
          {/* Groups */}
          <Stack
            sx={{
              maxWidth: '92%',
              marginInline: 'auto',
              marginBottom: '50px',
            }}
            justifyContent="center"
          >
            {groups && (
              <Filter
                categories={groups}
                setCategories={setGroups}
                selected={selectedGroup}
                setSelected={setSelectedGroup}
                type="קבוצה"
              />
            )}
            {/* {selectedGroup && ( */}
            <Grow in={selectedGroup} mountOnEnter unmountOnExit>
              <Stack>
                <Typography variant="runningText1" sx={{ marginTop: '15px' }}>
                  עריכה של קבוצה שנבחרה
                </Typography>
                <Stack direction="row" sx={{ marginTop: '15px' }} spacing={2}>
                  <Button
                    variant="containedBlue"
                    sx={{
                      width: 'fit-content',
                    }}
                    onClick={updateGroup}
                  >
                    עדכן אנשים בקבוצה נוכחית
                  </Button>
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="containedBlue"
                      sx={{
                        width: 'fit-content',
                      }}
                      onClick={updateGroupName}
                    >
                      שנה/י שם לקבוצה
                    </Button>
                    <TextField
                      className="form-input"
                      placeholder=" שם חדש לקבוצה "
                      onChange={(e) => setSelectedGroupName(e.target.value)}
                      // sx={{ width: '90%', marginInline: 'auto' }}
                    />
                  </Stack>
                  <Button
                    variant="containedRed"
                    sx={{
                      width: 'fit-content',
                    }}
                    onClick={() => {
                      setDialogObject({
                        open: true,
                        ok: ' למחוק את הקבוצה?',
                        cancel: 'בטל',
                        call_on_submit: handleRemoveGroup,
                      });
                    }}
                  >
                    מחק קבוצה
                  </Button>
                </Stack>
              </Stack>
            </Grow>
            {/* )} */}
            <Stack sx={{ marginTop: '15px' }} spacing={2}>
              <Typography variant="runningText1" sx={{ marginBottom: '15px' }}>
                יצירת קבוצה חדשה
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="containedBlue"
                  sx={{
                    width: 'fit-content',
                  }}
                  onClick={createGroup}
                >
                  צור קבוצה חדשה מרשימת עובדים נוכחית
                </Button>
                <TextField
                  className="form-input"
                  placeholder=" שם לקבוצה חדשה "
                  onChange={(e) => setNewGroupName(e.target.value)}
                  // sx={{ width: '90%', marginInline: 'auto' }}
                />
              </Stack>
            </Stack>
          </Stack>

          {/* Message */}
          <Stack
            sx={{
              maxWidth: '92%',
              marginInline: 'auto',
              marginBottom: '50px',
            }}
            justifyContent="center"
          >
            <Typography variant="runningText1" mb={2}>
              רשום/י את ההודעה לעובדים שנבחרו
            </Typography>
            <TextField
              className={classes.input}
              // className='form-input'
              placeholder=" הודעה "
              onChange={(e) => setMessage(e.target.value)}
              // sx={{ width: '90%', marginInline: 'auto' }}
            />
          </Stack>
          {/* Link */}
          <Stack
            sx={{
              maxWidth: '92%',
              marginInline: 'auto',
              marginBottom: '50px',
            }}
            justifyContent="center"
          >
            <Typography variant="runningText1" mb={2}>
              יש להדביק כאן לינק אליו רוצים להוביל
            </Typography>
            <TextField
              className={classes.input}
              // className='form-input'
              placeholder=" לינק "
              onChange={(e) => setLink(e.target.value)}
              // sx={{ width: '90%', marginInline: 'auto' }}
            />
          </Stack>
          <Stack mb={5} sx={{ width: '100%' }}>
            <Button
              variant="containedBlue"
              onClick={handleSendData}
              sx={{
                marginInline: 'auto!important',
              }}
            >
              שלח
            </Button>
          </Stack>
          <Box margin="0 10px 10px">
            <Button
              variant="containedBlue"
              onClick={() => {
                setFilteredUsers([]);
              }}
            >
              מחק הכל
            </Button>
          </Box>
          <Stack
            direction={{ sm: 'row' }}
            flexWrap="wrap"
            justifyContent="space-around"
            alignItems="center"
            spacing={1}
          >
            {filteredUsers && filteredUsers.length ? (
              filteredUsers.slice(0, visibleItems).map((user) => (
                <Box
                  key={user.id}
                  sx={{
                    width: '18%',
                    position: 'relative',
                    '& .user-card': {
                      width: '100%',
                      marginInline: 'auto',
                    },
                  }}
                >
                  <DisabledByDefaultIcon
                    sx={{
                      position: 'absolute',
                      top: '5px',
                      right: '10px',
                      zIndex: 1,
                      cursor: 'pointer',
                    }}
                    onClick={() => removeUserFromList(user.id)}
                  />
                  <UserCard user={user} key={user.id} />
                </Box>
              ))
            ) : (
              <Typography
                variant="runningText3"
                component="h3"
                align="center"
                sx={{
                  // width: '100%',
                  background: theme.palette.secondary.dark,
                  padding: '10px',
                }}
              >
                לא נמצאו עובדים
              </Typography>
            )}
          </Stack>

          {visibleItems < filteredUsers?.length && (
            <Button
              onClick={loadMore}
              variant="containedBlue"
              sx={{
                marginInline: 'auto',
                display: 'block',
              }}
            >
              הצג עוד
            </Button>
          )}
        </>
      ) : (
        <LogoLoader />
      )}
    </Container>
  );
});

export default PushNotifications;
