import React, { useState } from 'react';

import AlertPopupContainer, {
  HIDDEN_ALERT_OBJECT,
} from '../helpers/AlertPopup';
import DialogPopupContainer, {
  HIDDEN_DIALOG_OBJECT,
} from '../helpers/DialogPopup';

const AlertContext = React.createContext({});

export default AlertContext;

export const AlertContextProvider = ({ children }) => {
  const [alertObject, setAlertObject] = useState(HIDDEN_ALERT_OBJECT);
  const [dialogObject, setDialogObject] = useState(HIDDEN_DIALOG_OBJECT);

  const appContextData = {
    alertObject,
    setAlertObject,
    dialogObject,
    setDialogObject,
  };

  return (
    <AlertContext.Provider value={appContextData}>
      {children}
    </AlertContext.Provider>
  );
};
