import { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { fetchAllPosts } from '../api/api';
import { alertMessage } from '../helpers/AlertMessage.js';
import { handlingAfterFetch } from '../helpers/Tools.js';
import AppContext from '../contexts/AppContext';
import AlertContext from '../contexts/AlertContext';

import LogoLoader from '../components/LogoLoader.jsx';

// Mui
import { makeStyles } from '@mui/styles';
import theme from '../styles/CustomTheme.jsx';
import { Box, Container, Button, Stack, Typography } from '@mui/material';
import CustomPostCard from '../components/CustomPostCard.jsx';

const useStyles = makeStyles((theme) => ({
  mainTitleWrapper: {
    backgroundColor: `${theme.palette.primary.main}`,
    padding: '25px 15px',
    borderRadius: '30px 30px 0px 0px',
    // marginBottom: '30px',
  },

  marginBottom: {
    marginBottom: '30px',
  },

  mainTitle: {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: 1.1,
    color: `${theme.palette.my_white.main}`,
  },
}));

function CategoryPage() {
  const { id, type } = useParams();
  const [posts, setPosts] = useState(undefined);
  const [category, setCategory] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const classes = useStyles();
  const { postsCategories } = useContext(AppContext);
  const { setAlertObject } = useContext(AlertContext);

  const fetchPosts = async () => {
    if (type === 'event') {
      await handlingAfterFetch(
        fetchAllPosts(type, false, id),
        (res) => {
          if (res.status === 200) {
            setPosts(res.fields);
            setIsLoading(false);
          } else {
            alertMessage('שגיאה פנימית', 'error');
            setIsLoading(false);
          }
        },
        setAlertObject,
      );
    } else if (type === 'benefit') {
      await handlingAfterFetch(
        fetchAllPosts(type, false, id),
        (res) => {
          if (res.status === 200) {
            setPosts(res.fields);
            setIsLoading(false);
          } else {
            alertMessage('שגיאה פנימית', 'error');
            setIsLoading(false);
          }
        },
        setAlertObject,
      );
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    if (postsCategories?.length) {
      let theCat = postsCategories.find((c) => c.id === +id);
      setCategory(theCat);
    }
  }, [postsCategories]);

  return (
    <Container className="rounded">
      <>
        <Box
          className={`page-title ${classes.mainTitleWrapper} ${classes.marginBottom}`}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              component="h1"
              variant="h1"
              className={classes.mainTitle}
            >
              {type === 'event' ? 'אירועים' : 'הטבות'}
              {' - '}
              {category?.name}
            </Typography>
          </Stack>
        </Box>
        <Stack direction="row" flexWrap="wrap">
          {posts === undefined ? (
            <LogoLoader />
          ) : posts.length ? (
            posts?.map((post) => {
              return (
                <CustomPostCard
                  key={post?.id}
                  postType={type}
                  post={post}
                  category={postsCategories?.find(
                    (category) => category.id === +post.post_categories[0],
                  )}
                  marginInline="15px"
                />
              );
            })
          ) : (
            <Typography
              component="h2"
              variant="runningText1"
              className={classes.noPosts}
            >
              {type === 'benefit' ? 'אין הטבות' : 'אין אירועים'}
            </Typography>
          )}
        </Stack>
      </>
    </Container>
  );
}

export default CategoryPage;
