import Axios from 'axios';

export const SERVER_ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3011' //put in .env
    : 'https://newapp.portaltempo.co.il'; //put in .env

const parseRequestResponse = async (request, description) => {
  try {
    const response = await request;
    // console.log('🚀 ~ response:', response);

    const common_data = {
      status: response.status,
      statusText: response.statusText,
    };
    if (response.status === 200) {
      const result = response.data;

      if (result.success) {
        return { ...common_data, ...result };
      } else {
        return {
          ...common_data,
          ...result,
          message: `${description} Error: ${result.message}`,
        };
      }
    } else if (response.status === 401) {
      // bad login data
      return {
        ...common_data,
        message: `Wrong user credentials or user session is expired! Please, re-login...`,
      };
    } else {
      //   console.log(
      //     '🚀 ~ file: index.js:38 ~ parseRequestResponse ~ error:',
      //     response
      //   );
      return {
        ...common_data,
        message: `Server has responded with eror status ${response.status} on getting ${description}: ${response.statusText}`,
      };
    }
  } catch (error) {
    console.log(
      '🚀 ~ file: index.js:42 ~ parseRequestResponse ~ error:',
      error,
    );
    alert(error.response?.data?.message);
    return {
      status: 0,
      statusText: '',
      //   message: `Unexpected error while processing ${description}: ${error.response.data.message}`,
    };
  }
};

export const fetchGet = async (
  url,
  description,
  token = localStorage.getItem('token'),
) => {
  const result = await parseRequestResponse(
    Axios.get(url, {
      withCredentials: true,
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: token,
        'Access-Control-Allow-Origin': SERVER_ENDPOINT,
      },
    }),
    description,
  );
  return result;
};

export const fetchGetFile = async (
  url,
  description,
  token = localStorage.getItem('token'),
) => {
  const result = await Axios.get(url, {
    withCredentials: true,
    mode: 'cors',
    credentials: 'include',
    responseType: 'arraybuffer',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': SERVER_ENDPOINT,
    },
  });
  //   console.log(
  //     '🚀 ~ file: index.js:82 ~ result:',
  //     result
  //   );

  return result;
};

export const fetchPost = async (
  url,
  data,
  content_type = 'application/json',
  description = '',
  token = localStorage.getItem('token'),
) => {
  //   console.log(data);
  return await parseRequestResponse(
    Axios.post(url, data, {
      withCredentials: true,
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': content_type,
        Authorization: token,
        'Access-Control-Allow-Origin': SERVER_ENDPOINT,
        // 'https://reg.portaltempo.co.il:3010', for production only
      },
    }),
    description,
  );
};

export const fetchPut = async (
  url,
  data,
  content_type = 'application/json',
  description = '',
  token = localStorage.getItem('token'),
) => {
  return await parseRequestResponse(
    Axios.put(url, data, {
      withCredentials: true,
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': content_type,
        Authorization: token,
        'Access-Control-Allow-Origin': SERVER_ENDPOINT,
        // 'Access-Control-Allow-Origin':
        // 	'https://reg.portaltempo.co.il:3010', for production only
      },
    }),
    description,
  );
};

export const fetchDelete = async (
  url,
  data,
  content_type = 'application/json',
  description = '',
  token = localStorage.getItem('token'),
) => {
  return await parseRequestResponse(
    Axios.delete(url, {
      withCredentials: true,
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: token,
        'Content-Type': content_type,
        'Access-Control-Allow-Origin': SERVER_ENDPOINT,
      },
      data,
    }),
    description,
  );
};
