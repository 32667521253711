import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoLoader from '../LogoLoader.jsx';

// functions
import { fetchPostsByIds, fetchRecentPosts } from '../../api/api.js';
import { handlingAfterFetch } from '../../helpers/Tools.js';

import AlertContext from '../../contexts/AlertContext';

// Mui
import theme from '../../styles/CustomTheme.jsx';
import { Stack, Typography, Box } from '@mui/material';

function PostCards({ page_field, type, title }) {
  const navigate = useNavigate();

  const { setAlertObject } = useContext(AlertContext);

  const [newPosts, setNewPosts] = useState(undefined);

  const getPostsByIds = async () => {
    await handlingAfterFetch(
      fetchPostsByIds(page_field, type),
      (res) => {
        if (res.status === 200 && res.fields) {
          setNewPosts(res.fields);
        } else {
          alert('Some problem with Getting discounts by ids');
          setNewPosts([]);
        }
      },
      setAlertObject,
    );
  };

  useEffect(() => {
    if (page_field?.length > 0) {
      getPostsByIds();
    }
  }, [page_field]);

  return (
    <Box sx={{ cursor: 'pointer' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: '20px' }}
      >
        <Typography
          color={theme.palette.primary.dark}
          sx={{
            typography: {
              xs: 'h2',
              sm: 'headline1',
            },
          }}
        >
          {title}
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        flexWrap="wrap"
        alignItems="center"
        // justifyContent='space-between'
        useFlexGap
        // spacing={3}
        sx={{ columnGap: '30px' }}
      >
        {newPosts &&
          newPosts.length &&
          newPosts.map((post, idx) => {
            return (
              <Box
                key={idx}
                sx={{ position: 'relative' }}
                onClick={() => navigate(`/post/${post.id}`)}
              >
                <Box
                  component="img"
                  src={post.acf.image}
                  sx={{
                    position: 'relative',
                    borderRadius: '10px',
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '0',
                    color: 'white',
                    padding: '15px',
                  }}
                >
                  <Typography
                    component="h2"
                    variant="mobileHeadline"
                    sx={{ color: 'white' }}
                  >
                    {post.title.rendered}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        {newPosts === undefined && <LogoLoader />}
      </Stack>
    </Box>
  );
}

export default PostCards;
