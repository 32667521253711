import React, { useContext, useEffect, useState } from 'react';
import { useWindowSize } from '../helpers/useWindowSize.jsx';

import 'swiper/css';
import 'swiper/css/navigation';

import AppContext from '../contexts/AppContext';
import UserContext from '../contexts/UserContext';
import AlertContext from '../contexts/AlertContext';

import LogoLoader from '../components/LogoLoader.jsx';
// Mui

import { Container, Divider, Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// functions
import { fetchPage, fetchPostsCategories } from '../api/api.js';
import { handlingAfterFetch } from '../helpers/Tools.js';

// Pats

import Survey from '../components/Surveys/Survey.jsx';
import EventCards from '../components/GreenWay/EventCards.jsx';
import GreenPageGalleries from '../components/GreenWay/GreenPageGalleries.jsx';
import PostCards from '../components/GreenWay/PostCards.jsx';
const useStyles = makeStyles((theme) => ({
  mainTitleWrapper: {
    backgroundColor: `${theme.palette.primary.main}`,
    padding: '25px 15px',
    borderRadius: '30px 30px 0px 0px',
  },

  marginBottom: {
    marginBottom: '30px',
  },

  mainTitle: {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: 1.1,
    color: `${theme.palette.my_white.main}`,
  },
}));

function GreenWay() {
  const classes = useStyles();

  const { setAlertObject } = useContext(AlertContext);
  const { userFields } = useContext(UserContext);
  const { postsCategories, setPostsCategories } = useContext(AppContext);
  const [width, height] = useWindowSize();

  const [pageInfo, setPageInfo] = useState(null);

  const getPageInfo = async (page_id) => {
    await handlingAfterFetch(
      fetchPage(page_id),
      (res) => {
        if (res.status === 200 && res.fields) {
          setPageInfo(res.fields);
        } else {
          alert('Some problem with Getting Page info');
        }
      },
      setAlertObject,
    );
  };

  const fetchCategories = async () => {
    await handlingAfterFetch(
      fetchPostsCategories('post'),
      (res) => {
        res.status === 200 && setPostsCategories(res.fields);
      },
      setAlertObject,
    );
  };

  useEffect(() => {
    getPageInfo(1022);
    if (!postsCategories) fetchCategories();
  }, []);

  return pageInfo ? (
    <Container
      sx={{
        marginBlock: '25px',
        paddingBlock: '25px',
      }}
      className="rounded"
    >
      <Box
        className={`page-title ${classes.mainTitleWrapper}`}
        sx={{ marginBottom: '0px' }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography component="h1" variant="h1" className={classes.mainTitle}>
            {pageInfo.title.rendered}
          </Typography>
        </Stack>
      </Box>
      <Box mb={3} sx={{ position: 'relative' }}>
        <Box
          src={pageInfo.acf.hero_image.url}
          component="img"
          sx={{
            aspectRatio: 1,
            objectFit: 'cover',
            maxHeight: '600px',
            width: '100%',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '0',
            color: 'white',
            padding: '15px',
          }}
        >
          <Typography
            component="h2"
            variant="mobileHeadline"
            sx={{ color: 'white' }}
          >
            {pageInfo?.acf?.title}
          </Typography>
          {/* <Typography
            variant='runningText2'
            sx={{ color: 'white' }}
          >
            {pageInfo?.acf?.text}
          </Typography> */}
        </Box>
      </Box>
      <Box
        mb={3}
        dangerouslySetInnerHTML={{
          __html: pageInfo?.acf?.text,
        }}
        sx={{ marginInline: '25px' }}
      ></Box>
      <Box sx={{ paddingInline: '15px' }}>
        {pageInfo?.acf?.activities && (
          <EventCards
            categories={postsCategories}
            page_field={pageInfo?.acf?.activities}
            type="event"
          />
        )}
        {pageInfo?.acf?.survey &&
          ((userFields.my_surveys &&
            !userFields.my_surveys.find(
              (survey) => survey === pageInfo.acf.survey,
            )) ||
            (!userFields.my_surveys && <Survey id={pageInfo?.acf.survey1} />))}
        {pageInfo?.acf?.posts && (
          <PostCards
            title={pageInfo?.acf?.posts_title}
            page_field={pageInfo?.acf?.posts}
            type="posts"
          />
        )}
        {pageInfo?.acf?.gallery && (
          <GreenPageGalleries galleryId={pageInfo?.acf?.gallery} />
        )}
      </Box>
    </Container>
  ) : (
    <LogoLoader />
  );
}

export default GreenWay;
