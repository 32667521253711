import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoLoader from '../LogoLoader.jsx';

// functions
import { fetchPostsByIds } from '../../api/api.js';
import { handlingAfterFetch } from '../../helpers/Tools.js';

import AlertContext from '../../contexts/AlertContext';

// Pats
import CustomPostCard from '../CustomPostCard.jsx';

// Mui
import theme from '../../styles/CustomTheme.jsx';
import { Box, Button, Stack, Typography } from '@mui/material';

function EventCards({ categories, page_field, type }) {
  const navigate = useNavigate();

  const { setAlertObject } = useContext(AlertContext);

  const [newPosts, setNewPosts] = useState(undefined);

  const getPostsByIds = async () => {
    await handlingAfterFetch(
      fetchPostsByIds(page_field, type),
      (res) => {
        if (res.status === 200 && res.fields) {
          setNewPosts(res.fields);
        } else {
          alert('Some problem with Getting discounts by ids');
          setNewPosts([]);
        }
      },
      setAlertObject,
    );
  };

  useEffect(() => {
    if (page_field?.length > 0) {
      getPostsByIds();
    }
  }, [page_field]);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: '20px' }}
      >
        <Typography
          color={theme.palette.primary.dark}
          sx={{
            typography: {
              xs: 'h2',
              sm: 'headline1',
            },
          }}
        >
          {type === 'event' ? 'פעילויות' : '  הטבות חדשות'}
        </Typography>
        <Button
          variant="containedLightBlue"
          onClick={() => {
            window.scrollTo(0, 0);
            type === 'event'
              ? navigate('/category/event/78')
              : navigate('/category/benefit/78');
          }}
        >
          {type === 'event' ? 'צפה בכל ההתנדבויות' : ' לכל ההטבות'}
        </Button>
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        flexWrap="wrap"
        alignItems="center"
        // justifyContent='space-between'
        useFlexGap
        // spacing={3}
        sx={{ columnGap: '30px' }}
      >
        {newPosts &&
          newPosts.length &&
          newPosts.map((post) => {
            return (
              <CustomPostCard
                postType={post.type}
                key={post?.id}
                post={post}
                category={categories?.find(
                  (category) => category.id === post.post_categories[0],
                )}
              />
            );
          })}
        {newPosts === undefined && <LogoLoader />}
      </Stack>
    </Box>
  );
}

export default EventCards;
